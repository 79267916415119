#login-selection {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	background-color: $color-blue;
	transform: translate(-100%, -100%);
	transition: opacity 1s, transform 0s linear 1s;
	overflow-y: auto;
	padding: get-vw(120px,1366px) 20px;
	@include break(nav,mobile){
		padding-top: 20px;
	}
	&.active {
		transform: none;
		opacity: 1;
		transition: opacity 1s;
	}
	> div {
		@include break(tab,desktop){
			display: flex;
			justify-content: center;
		}
		clear: both;
		max-width: 1174px;
		margin: 0 auto;
		> * {
			flex: 1;
			background: $color-white no-repeat center center;
			background-size: cover;
			padding: get-vw(28px,1366px) get-vw(28px,1366px) 104px get-vw(28px,1366px);
			position: relative;
			border-radius: 2px;
			border: 2px solid #f4eded;
			margin-bottom: 20px;

			@include break(tab,desktop){
				max-width: 350px;
				-ms-flex: 1 0 auto;
				&:not(:first-child){
					margin-left: get-vw(13px,1366px);
				}
				&:not(:last-child){
					margin-right: get-vw(13px,1366px);
				}
			}
			> [role="button"] {
				position: absolute;
				bottom: 28px;
				left: 50%;
				transform: translateX(-50%);
				width: 80%;
			}
		}
	}
	> button {
		@include break(nav,desktop){
			position: absolute;
			padding: 15px;
		}
		float: right;
		top: 10px;
		right: 20px;
		
		color: $color-white;
		font-size: 24px;
	}
}