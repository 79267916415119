inner-content {
	@extend %block;
	@extend %contain;
	position: relative;
	z-index: 1;
	@media (min-width: 666px){
		min-width: 260px;
	}
	@include headings(2,4){
		margin: {
			top: 0;
			bottom: 28px;
		}
		
		+ h6 {
			margin: {
				top: -12px;
				bottom: 28px;
			}
		}
	}
	p:last-of-type {
		//margin-bottom: 42px;
	}
	
	&[alignment="Left"]{
		text-align: left;
	}
	&[alignment="Center"]{
		text-align: center;
	}
	&[alignment="Right"]{
		// Replace with element query
		@media(min-width: 768px){
			text-align: right;
		}
	}
}