comp-video {
	@extend %block;
	@extend %top-level-component;
	overflow: hidden;
	position: relative;
	padding: 0 !important;
	@include break(nav,mobile){
		header + & {
			margin-top: 80px;
			@media(max-width: 640px){
				margin-top: 12.5vw;
			}
		}
	}
	&::before {
		content: "";
		display: block;
		padding-bottom: 56.25%;
		box-sizing: content-box;
	}
	> * {
		position: absolute !important;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}