@each $label, $map in $themes {
	$background: map-get($map, background);
	$headings: map-get($map, headings);
	$headings-alt: map-get($map, headings-alt);
	$copy: map-get($map, copy);
	$link: map-get($map, link);
	$divider: map-get($map, divider);
	$button-border: map-get($map, button-border);
	$button-background: map-get($map, button-background);

	[theme="#{$label}"] {
		@if $background {
			background-color: $background;
		}
		@if $headings {
			@include headings(){
				color: $headings !important;
				@if $headings-alt {
					strong {
						color: $headings-alt;
					}
				}
			}
		}
		@if $headings-alt {
			@include headings(6){
				color: $headings-alt;
			}
		}
		@if $copy {
			color: $copy;
		}
		@if $link {
			a, button.text {
				color: $link;
			}
		}
		@if $divider {
			@include headings(1,2){
				background-image: linear-gradient($divider,$divider);
			}
		}
		@if $button-border or $button-background {
			a[role="button"].block {
				@if $button-border {
					box-shadow: inset 3px 3px 0 $button-border, inset -3px -3px 0 $button-border;
				}
				@if $button-background{
					background-color: $button-background;
				}
			}
		}

	}
}

// body > [theme="White"] + [theme="White"] {
// 	padding-top: 80px;
// }