comp-container{
	@extend %block;
	@extend %top-level-component;
	@include comp-padding(side);
	position: relative;
	z-index: 0;
	overflow: hidden;
 
	&[home] {
		overflow: visible;
		@include comp-padding(vert,1.5%,!important);
		padding: {
			top: 1.5%;
			bottom: 1.5%;
		}
		img {
			position: relative;
			z-index: 1;
			margin-left: -7%;
		}
		inner-content {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 2.6%;
			width: 37%;
		}
	}
}