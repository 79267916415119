%dealers-location-action {
	font-weight: bold;
	text-transform: uppercase;
	font-family: $headings-font-family;
	font-weight: $font-weight-demi;
	font-size: 14px;
}

%dealer-actions {
	@include break(nav,desktop){
		display: flex;
		> * {
			flex: 1;
		}
	}
	span {
		@extend %dealers-location-action;
	}
	.details {
		@extend %dealers-expand;
		font-size: 16px;
		padding-left: 0;
		&.detail {
			color: $color-blue;
		}
	}
}

%dealers-expand {
	@include iconize(chev-down,after);
	&::after {
		display: inline-block;
		vertical-align: middle;
		margin-left: 26px;
		transition: transform 0.25s;
	}
	&.detail::after {
		transform: rotate(180deg);
	}
}

%dealer-location {
	border-bottom: $divider-border-dark;
	padding: 30px 0;
	&:first-child {
		border-top: $divider-border-dark;
	}
	> .details {
		&:not(.expanded) {
			@extend %collapsible-collapsed;
		}
		&.expanded {
			@extend %collapsible-expanded;
		}
		.extended {
			&:not(.expanded) {
				@extend %collapsible-collapsed;
			}
			&.expanded {
				@extend %collapsible-expanded;
			}
		}
		.default {
			@extend %dealers-expand;
			display: inline;
			cursor: pointer;
		}
		h6 {
			color: $color-blue;
			margin-top: 30px;
		}
	}
	a {
		color: inherit;
	}
}

%dealer-content {
	> p {
		margin-bottom: 0;
	}
	.departments {
		margin: 40px 0 10px;
		> span {
			margin: 10px 0;
			display: inline-block;
			width: 140px;
			font-family: $font-family-sans-serif;
			font-size: 10px;
			text-transform: uppercase;
			font-weight: $font-weight-light;
			&.parts {
				@include iconize(shock);
			}
			&.sales {
				@include iconize(tag);
			}
			&.service {
				@include iconize(tools);
			}
			&::before {
				display: inline-block;
				height: 50px;
				width: 50px;
				border: $divider-border-dark;
				border-radius: 50%;
				vertical-align: middle;
				margin-right: 10px;
				text-align: center;
				line-height: 50px !important;
				font-size: 25px;
			}
		}
	}
}



comp-dealers {
	@extend %block;
	@extend %top-level-component;
	padding-top: 0 !important;
	position: relative;
	@include break(nav,mobile){
		nav {
			display: none;
		}
	}
	.marker-label {
		width: 30px;
		text-align: center;
		color: #6F87A6;
	}
	> header {
			h1 {
				@include break(nav,desktop){
					margin-top: 0;
				}
				padding: 0;
				background: none;
				font-size: 48px;
				margin-bottom: 50px;
				a {
					display: inline-block;
					vertical-align: middle;
					margin-left: 30px;
					margin-bottom: 6px;
					padding-left: 30px;
					border-left: 1px solid $color-blue;
					font-size: 20px;
					line-height: 35px;
				}
			}
		//}



	}
	> inner-content {
		@include break(nav,desktop){
			display: flex;
			> * {
				flex: 1;
			}
		}
		> aside {
			@include break(nav,desktop){
				flex-basis: 38%;
				order: 2;
				margin-left: 44px;
				text-align: center;
				
			}
			@include break(nav,mobile){
				position: fixed;
				width: 100%;
				bottom: 100%;
				left: 0;
				opacity: 0;
			}
			> button {
				&::before {
					content: "View Larger Map"
				}
			}
			> a {
				display: block;
				margin-top: 20px;
			}
		}
		> section {
			flex-basis: 62%;
			> header {
				padding-bottom: 30px;
				margin-bottom: 30px;
				border-bottom: $divider-border-dark;
				comp-tabs {
					padding-bottom: 30px;
				}
				nav {
					margin-bottom: 14px;
					button {
						&:first-child {
							@include iconize(location);
						}
						&:last-child {
							@include iconize(route);
						}
						&::before {
							display: inline-block;
							margin-right: 10px;
							font-size: 20px;
							vertical-align: middle;
						}
					}
				}
				[filters-toggle] {
					color: #a3a3a3;
					padding-left: 40px;
					position: relative;
					@include iconize(filter);
					@extend %dealers-expand;

					&::before, &::after {
						color: $color-blue;						
					}

					&::before {
						position: absolute;
						font-size: 23px;
						top: 50%;
						left: 0;
						transform: translateY(-50%);

					}
				}
				[filters] {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap; 
					padding-top: 10px;
					&:not(.expanded) {
						@extend %collapsible-collapsed;
					}
					&.expanded {
						@extend %collapsible-expanded;
					}
					> * {
						flex-basis: 50%;
						max-width: 50%;

						// flex-basis: 33.3%;
						// max-width: 33.3%;

						// &:first-child {
						// 	flex-basis: 66.6%;
						// 	max-width: 66.6%
						// }

						//border: $divider-border-dark;
						box-shadow: 0 0 0 1px $divider-color-dark;
						background: #f8f8f8;
						font-size: 14px;
						font-family: $headings-font-family;
						padding: 12px 12px 12px 58px;
						position: relative;
						cursor: pointer;
						transition: background-color 0.25s;
						html:not(.touch) &:not(.selected):hover {
							background: darken(#f8f8f8,10%);
						}
						@include iconize(check);
						&::before {
							position: absolute;
							top: 12px;
							left: 12px;
							width: 20px;
							height: 20px;
							line-height: 18px;
							text-align: center;
							background: $color-white;
							border: $divider-border-dark;
							color: $color-blue;
						}
						&:not(.selected) {
							&::before {
								content: "";
							}
						}
					}
				}
			}

				[fields]{
					&[route] {
						padding-left: 40px;
						[field] {
							position: relative;
							&::before {
								position: absolute;
								top: 50%;
								left: -40px;
								transform: translateY(-50%);
								color: $color-blue;
							}
							&:first-child {
								@include iconize(close);
								&::before {
									font-size: 16px;
								}
								&::after {
									content: "";
									position: absolute;
									left: -33px;
									top: 78%;
									bottom: -87%;
									border-left: 1px dashed $color-blue;
								}
							}
							&:last-child {
								@include iconize(location);
								&::before {
									font-size: 23px;
									left: -44px;
								}
							}

							
						}
					}

					[field] {
						@extend %block;
						border: $divider-border-dark;
						position: relative;
						padding-right: 70px;
						margin-bottom: 30px;
						input {
							border: 0;
							font-size: 20px;
							color: $color-blue;
							font-family: $headings-font-family;
							width: 100%;
							padding: 5px 15px;
							font-weight: bold;
						}
						button {
							position: absolute;
							right: 0;
							top: 0;
							bottom: 0;
							width: 70px;
							min-width: 0;
							background: solidGradient($divider-color-dark) no-repeat left center;
							background-size: 1px 80%;
							padding: 0;
							@include iconize(geo);
							&::before {
								font-size: 26px;
								color: $color-blue;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
				
				[selected] {
					display: none;

					.location {
						position: relative;

						[close] {
							position: absolute;
							right: 5%;
							top: 5%;
							cursor: pointer;
						}
					}
				}
			

			.location {
				@extend %dealer-location;
				html:not(.touch)  &:hover {
					background: #f9f9f9;
				}
			}
			[list] header {
				display: flex;
				margin-bottom: 20px;
				> h2 {
					flex: 0 1 auto;
					flex-grow: 1;
					padding: 0 25px 0 0;
					margin: 0;
					background: transparent;
					// IE 10 Fix 
				}
				> div {
					flex-shrink: 0;
					flex-basis: 22%;
					width: 22%;
					line-height: 33px;
					span {
						@extend %dealers-location-action;
						display: block;
						border-left: $divider-border-dark;
						padding-left: 25px;
					}
				}
				.open {
					color: #00a40c;
					&::before {
						content: "Open Now";
					}
				}
				.closed {
					color: #a40000;
					&::before {
						content: "Closed";
					}
				}
			}
			.content {
				@extend %dealer-content;
				
			}
			.actions {
				@extend %dealer-actions;
			}
		}
		[map] {
			height: 370px;
			&:not([style]) + button {
				display: none;
			}
			+ button {
				margin-top: 34px;
			}
		}
	}
	@include break(nav,desktop){
		&.fullscreen {
			[extra] {
				display: none !important;
			}
			> inner-content{
				> aside {
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					margin: 0;
					[map] {
						height: 100%;
					}
					> button {
						position: fixed;
						top: 0;
						left: 0;
						margin: 0;
						line-height: 30px;
						&::before {
							content: "\00ab";
							display: inline-block;
							vertical-align: middle;
							margin-right: 25px;
						}
						&::after {
							content: "Back to Listings";
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
				> section > header {
					position: fixed;
					top: 76px;
					left: 25px;
					width: 580px;
					max-width: 100%;
					padding-bottom: 0 !important;
					comp-tabs {
						padding: 0 20px 30px 20px;
					}
					[selected] {
						display: block;

						.location {
							margin: 40px 0 0 0 !important;
							background: $color-white;
							border: 1px solid #f4eded !important;
							padding: 20px;
						}
						.details {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}

[selected] .details {
	@include iconize(link,after);
}

comp-dealer {
	@extend %block;
	@extend %top-level-component;
	@extend %dealer-location;
	max-width: 600px !important;
	padding-top: 0 !important;
	header > h2 {
		background: none;
		padding-top: 0;
	}
	.content {
		@extend %dealer-content;
	}
	.actions {
		@extend %dealer-actions;
		padding: 20px 0;
		@include break(nav,desktop){
			> div:first-child a {
				border-right: 1px solid #ddd;
				padding-right: 80px;
			}
		}
	}
	item-department {
		@extend %block;
		margin-bottom: 40px;
		section {
			display: flex;
			> * {
				flex: 1;
			}
			> header {
				flex-basis: 30%;
				max-width: 30%;
			}
		}
		> section {
			> header {
				flex-basis: 15%;
				max-width: 15%;
			}
			
		}
	}
	.details {

		h4 {
			color: $color-blue;
			font-size: 16px;
			margin-bottom: 10px;
		}

		.dealer-services {
			li {
				position: relative;
				list-style-type: none;
				line-height: 26px;
				&::before {
					content: "";
					position: absolute;
					z-index: 1;
					width: 7px;
					height: 7px;
					background: $color-blue;
					transition: transform 0.25s cubic-bezier(0.390,  0.575, 0.565, 1.000);
					transition-delay: inherit;
					top: 10px;
					left: -25px;
					border-radius: 50%;
				}
			}
		}

		@include break(nav, desktop) {
			html:not(.safari) & {
				.dealer-services {
					column-count: 2;
					column-gap: 50px;
					column-width: 250px;
					margin-bottom: 60px;
				}
			}
		}
	}
}