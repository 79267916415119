comp-timeline {
	@extend %block;
	@extend %top-level-component;
	@extend %contain;
	background: linear-gradient(#e2e6e9,#e2e6e9) center top no-repeat;
	background-size: 1px 100%;
	@include break(tab,desktop){
		> * {
			width: 50%;
			&:nth-child(3){
				margin-top: 200px;
			}
		}
	}
	> [card] {
		@include break(tab,desktop){
			padding: 50px;
		}
		@include break(tab,mobile){
			padding: 25px 0;
		}
		&:not(.inview){
			figure {
				transform: scale(0);
				&::before, &::after {
					//opacity: 0;
				}
				&::before {
					transform: scale(0,1);
				}
				&::after {
					transform: scale(0);
				}
			}
		}
		figure {
			background: $color-white;
			box-shadow: 0 0 35px rgba(0,0,0,0.19);
			padding-bottom: 50px;
			position: relative;
			transition: transform 0.75s cubic-bezier(0.075,  0.820, 0.165, 1.000);
			> span, figcaption {
				padding: 0 30px;
				margin-top: 30px;
			}
			@include break(tab,desktop){
				&::before, &::after {
					top: 77px;
					
				}
				&::before {
					content: "";
					width: 0px;
					height: 0px;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					position: absolute;
					transition: transform 0.25s cubic-bezier(0.680, -0.550, 0.265, 1.550) 0.75s;
					transform-origin: 0 50%;
				}
				&::after {
					content: "";
					background: $color-white;
					width: 26px;
					height: 26px;
					border: 7px solid $color-blue;
					position: absolute;
					border-radius: 50%;
					transition: transform 0.75s cubic-bezier(0.190,  1.000, 0.220, 1.000) 1s;
				}
				&:not(.left){
					&::before {
						border-right: 10px solid $color-white;
						left: -10px;
					}
					&::after {
						left: -62px;
					}
				}
				&.left {
					&::before {
						border-left: 10px solid $color-white;
						right: -10px;
					}
					&::after {
						right: -62px;
					}
				}
			}
		}
		figcaption {
			font-family: $headings-font-family;
			font-weight: $font-weight-demi;
			color: $color-blue;
			font-size: 48px;
			line-height: 1;
			background: linear-gradient($link-color,$link-color) left top no-repeat;
			background-size: 2px 100%;
		}
		img {
			border: 6px solid transparent;
		}
	}

}