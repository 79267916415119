comp-callout {
	@extend %block;
	@extend %top-level-component;
	position: relative;
	//@include comp-padding(side);
	padding: 7.3%;
	&[theme="White"] {
		@include comp-padding(vert,0);
	}
	&[crop="None"]{
		comp-background {
			background: {
				position: center center;
				repeat: no-repeat;
				size: auto 70%;
			}
		}
		&[align="Top"]{
			comp-background {
				background: {
					position: center top;
					size: contain;
				}
			}
		}
		&[align="Bottom"]{
			comp-background {
				background: {
					position: center bottom;
					size: contain;
				}
			}
		}
	}

	@include break(nav,mobile){
		> [push] {
			display: none;
		}
		comp-background {
			position: absolute;
			padding-bottom: 0 !important;
			height: 100%;
		}
	}

	inner-content {
		@include max-center(true);
		> [role="button"] {
			margin: 5px;
			// @include break(nav,mobile){
			// 	display: block;
			// }
			// + [role="button"] {
			// 	@include break(nav,desktop){
			// 		margin-left: 5px;
			// 	}
			// 	@include break(nav,mobile){
					
			// 		margin-top: 5px;
			// 	}
			// }
		}
	}
	h2 {
		@extend %heading-center;
	}

	&[fof] {
		h1 {
			@extend %heading-center;
			span {
				display: inline-block;
				background: $color-gray;
				padding: 28px 60px;
				font-size: 122px;
			}
			+ h2 {
				background: none;
				color: $color-black;
				text-transform: none;
				margin-bottom: 20px;
			}
		}
		[role="button"] {
			margin-top: 40px;
			display: inline-block;
		}
	}

}