comp-cards {
	@extend %block;
	@extend %top-level-component;
	
	padding: {
		left: 11px;
		right: 11px;
		top: 11px !important;
		bottom: 11px !important;
	}
	> h1 {
		background: transparent;
		padding: 20px 10%;
	}
	> h3 {
		background: transparent;
		padding: 0 10% 20px 10%;
		&:not(:first-child) {
			margin-top: 0;
		}
	}
	> h1, > h3 {
		+ div {
			padding: 0 calc(10% - 11px) 7% calc(10% - 11px);
			justify-content: flex-start;
		}
	}
	&[alternating] {
		> div {
			> a:nth-child(6n), > a:first-child {
				@include break(nav,desktop){
					flex-basis: 66%;
					max-width: 66%;
					// comp-background {
					// 	padding-bottom: 34%;
					// }
				}	
				@include break(nav,mobile){
					flex-basis: 100%;
					max-width: 100%;
				}
			}
		}
	}
	&[fullwidth] {
		figcaption {
			text-align: left;
		}
		> div {
			> a {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
		// [byline] {
		// 	display: none !important;
		// }
	}
	&[layout="compact"] {
		> h1 {
			@extend .h3;
		}
		> h3 {
			@extend .h5;
		}
		> div {
			> a {
				figcaption {
					@extend .h4;
					padding: {
						top: 0 !important;
						bottom: 0 !important;
					}
					+ span {
						padding-top: 0;
					}
				}
			}
			@include break(nav,desktop){
				> a {
					flex-basis: 25%;
					max-width: 25%;
				}
				> span {
					flex-basis: 33%;
					max-width: 33%;
				}
			}
		}
	}
	> div {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include break(nav,desktop){
			> a {
				flex-basis: 33%;
				max-width: 33%;
			}
			> span {
				flex-basis: 50%;
				max-width: 50%;
			}
		}
		> * {
			@include break(nav,mobile){
				flex-basis: 50%;
				max-width: 50%;
			}
			@media(max-width: 650px){
				flex-basis: 100%;
				max-width: 100%;
			}
			display: block !important;
			box-sizing: border-box;
			padding: 11px;
		}
		> a {
			color: inherit;
			img {
				transition: opacity 0.25s;
			}
			comp-background {
				position: static;
				display: block;
				width: 100%;
				//padding-bottom: 68%;
				height: 230px;
				background-position: center top;
				background-size: cover;
			}
			&:hover {
				text-decoration: none;
				color: inherit;
				html:not(.touch) & {
					img {
						opacity: 0.75;
					}
				}
			}
			figure {
				border: 1px solid $color-gray;
				background: $color-white;
				color: $color-black;
				height: 100%;
				padding-bottom: 10px;
				> * {
					display: block;
				}
				&[theme="Blue"] {
					padding-bottom: 50px;
					position: relative;
					box-shadow: 0 0 10px rgba(68,68,68,0.13);
					figcaption {
						color: $color-white;
					}
					span {
						&[byline]{
							border-bottom: $divider-border;
							padding: 0;
							margin: 0 20px;
							display: flex;
							font-size: 12px;
							> * {
								flex: 1;
								margin: 0;
								&:last-child {
									text-align: right;
								}
								&.author::before {
									content: "Written by: ";
								}
							}
						}
						&[cta] {
							position: absolute;
							bottom: 0;
							left: 0;
							font-size: 16px;
						}
					}
				}
			}
			figcaption {
				@extend .h2;
				text-transform: uppercase;
				margin: 0;
				padding: 33px 20px 10px 20px !important;
				text-align: center;
				font-weight: $font-weight-demi;
				
			}
			span {
				padding: 20px 20px;
			}
		}
		> span {
			padding: 45px 0 34px 0;
			//border-bottom: 1px solid rgba(59,59,59,0.1);
			figure {
				@extend %contain;
				//position: relative;
				//padding-left: 50%;
				overflow: hidden;
				img {
					//position: absolute;
					top: 0;
					left: 0;
					width: 50% !important;
					float: left;
					border: 1px solid rgba(59,59,59,0.1);
				}
			}
			figcaption {
				@extend .h2;
				text-transform: uppercase;
				margin: 0;
				font-weight: $font-weight-demi;
				padding-top: 0 !important;
				@media(max-width: 920px){
					font-size: 20px;
				}
			}
			figcaption, a[role="button"] {
				padding: 10px 10px 10px 22px;
				width: 50%;
				float: right;
			}
			a[role="button"] {
				color: $color-blue !important;
				&::after {
					display: none !important;
				}
			}
		}
	}

	
	
}