comp-explorer {
	@extend %block;
	@extend %top-level-component;
	padding-top: 0 !important;
	> header {
		position: relative;
		&:not(:empty) {
			padding-bottom: 42.504259%;
		}
		> div {
			@extend %abs-cover;
			background: {
				size: auto 100%;
				position: center center;
				repeat: no-repeat;
			}
			transition: opacity 0.5s;
			&:not(.selected){
				opacity: 0;
			}
			> div {
				position: absolute;
				transform: translate(-50%, -50%);
				width: 8vw;
				height: 8vw;
				transition: opacity 0.2s;
				&:not(.active){
					opacity: 0;
				}
				&.active {
					> div {
						position: relative;
						width: 100%;
						height: 100%;
						> div {
							position: absolute;
							top: 50%;
							left: 50%;
							margin: 0;
							width: 0;
							height: 0;
							opacity: 0;
							width: 70%;
							height: 70%;
							transform: translate(-50%, -50%);
							border: 8px solid;
							border-radius: 50%;
							animation: point-ripple 2s linear infinite;
							&:nth-of-type(1) {
								border-color: $color-blue;
							}
							&:nth-of-type(2) {
								border-color: $color-steel;
								animation-delay: 0.5s;
							}
							&:nth-of-type(3) {
								border-color: $color-blue;
								animation-delay: 1s;
							}
							&:nth-of-type(4) {
								border-color: $color-steel;
								animation-delay: 1.5s;
							}
						}
					}
				}
				
			}
		}
	}
	> nav {
		@extend %tab-nav;
		margin-bottom: 20px;
	}
	comp-list {
		@extend %block;
		> div {
			text-align: center;
			> * {
				max-width: 786px;
				margin: {
					left: auto;
					right: auto;
				}
			}
		}
	}
	h2 {
		background: none;
		padding-top: 0;
	}
	&.loaded {
		comp-list {
			> div {
				//overflow: hidden;
				//max-height: 1000px;
				//transition: max-height 0.2s;
				&:not(.selected){
					//max-height: 0;
					display: none;
				}
			}
			&:not(.selected) {
				display: none;
			}
		}
	}
}

@keyframes point-ripple {
  0% {
    // width: 0;
    // height: 0;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    // width: 44%;
    // height: 44%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    // width: 88%;
    // height: 88%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
