$icon-font-name: 'icomoon';

%icon-font-defaults {
	font-family: $icon-font-name !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include font-face($icon-font-name, '../images/icon-font/#{$icon-font-name}', normal, normal, eot ttf woff svg);

@mixin iconize($icon,$location: before){
	&::#{$location} {
		@extend %icon-#{$icon};
	}
}

@each $name, $code in $font-icons {
  %icon-#{$name} {
    @extend %icon-font-defaults;
    content: $code;
  }
  .icon-#{$name}:before {
    @extend %icon-#{$name};
  }
}