article {
	.constrained {
		margin: 0 auto;
		padding: 38px 0;
		max-width: 680px;
		display: block;
		@media(max-width: 767px) {
			padding: 7.2%;
		}
		@media(max-width: 680px){
			max-width: 100%;
		}
		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}
	> heading {
        @extend .constrained;
        padding-top: 0;
		h1 {
			font-size: 44px;
			font-weight: 100;
		}
		// time {
		// 	@include iconize('time');
		// }
		// address {
		// 	@include iconize('user');
		// }
		// data {
		// 	@include iconize('category');
		// }
		time, address, data {
			display: inline-block;
			font-size: 12px;
			color: $color-blue;
			text-transform: uppercase;
			font-family: $headings-font-family;
			letter-spacing: .3em;
			margin-right: 50px;
			margin-bottom: 0;
			&::before {
				color: #bdbdbd;
				display: inline-block;
				margin-right: 5px;
			}
		}
		+ div {
			padding-top: 0;
		}
	}
	> img {
		@extend .constrained;
		padding: 0;
	}
	> blockquote {
		@extend .constrained;
		border: none;
		margin-top: 40px;
		//padding: 10px 0;
		border-bottom: 1px solid $divider-color;
		margin-bottom: 10px;
		font-size: 26px;
		line-height: 1.25;
	}
	> div {
		
		@extend .constrained;
		@include headings(1,6){
			&:first-child {
				margin-top: 0;
			}
		}
		p {
			font-size: 18px;
			&:not(:last-child){
				margin-bottom: 2em;
			}
			&.vid-holder {
				position: relative;
				padding-bottom: 56.5%; /* height */
				height: 0;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
					background: transparent;    
				}
			}
		}
		li {
			font-size: 18px;
		}
		ul {
			@media (min-width: 768px){
				&[col]{
					column-gap: 40px;
				}
				&[col="2"]{
					column-count: 2;
				}
				&[col="3"]{
					column-count: 3;
				}
			}

			// list-style-type: disc; 
			// list-style-position: outside; 
			// li {
			// 	list-style-type: disc; 
			// 	list-style-position: outside; 
			// 	margin-left: 15px; 
			// }

			list-style: none;
			padding:0;
			margin:0;
			&:not(:last-child){
				margin-bottom: 2em;
			}
			
			&:not([dealer-list]){
				li { 
					//margin-left: 1em;
					padding-left: 2.5em; 
					text-indent: -.7em;
					&:not(:last-of-type){
						margin-bottom: 1em;
					}
					&:before {
						content: "• ";
						display: inline-block;
						margin-right: 0.2em;
						color: $color-blue; /* or whatever color you prefer */
					}
				}
			}

			&[dealer-list]{
				margin-top: 2em;
			}
		}
		img {
			height: auto !important;
			&:not([style*="float:"]) {
				margin-bottom: 1em;
			}
			&[style*="float: left"] {
				margin-right: 1em;
			}
			&[style*="float: right"] {
				margin-left: 1em;
			}
			@media(max-width: 599px){
				&[style*="float:"] {
					margin: {
						left: 0;
						right: 0;
					}
					float: none !important;
					display: block;
					margin-bottom: 1em;
				}
			}
			
		}
		> .location {
			padding-top: 7%;
			margin-top: 7%;

			border-top: 1px solid #e1e1e1;
			h2 {
				color: #484848;
				cursor: pointer;
				margin: {
					top: 0;
				}
				a {
					color: #484848;
					text-decoration: none;
				}
			}
			// h3, h4 {
			// 	margin-left: 34px;
			// }
			> a {
				text-decoration: none;
				h3 {
					color: $color-blue;
					position: relative;
					transform: translateZ(0);
					font-size: 17.28px;
					&::before {
						//content: "";
						position: absolute;
						top: -2px;
						left: -34px;
						width: 20px;
						height: 20px;
						font-size: 22px;
						// background: no-repeat center top;
						// background-size: contain;
					}

				}
			}
		}
	}
	> nav {
		display: flex;
		margin-bottom: 80px;
		> a {
			display: flex;
			flex: 1;
			margin: 0 2px;
			background: #f0eeee;
			padding: 40px 0;
			transition: background-color .25s;
			@media(max-width: 767px) {
				padding: 14px 0;
				flex-direction: column;
				&.next {
					flex-direction: column-reverse;
					text-align: right;
				}
				.dir {
					margin-bottom: 10px;
				}

			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			&:hover, &:focus {
				text-decoration: none;
				html:not(.touch) & {
					background: $color-blue;
					*, ::before, ::after {
						color: #fff !important;
						
					}
				}
			}
			span {
				flex: 1;
				padding: 0 get-vw(88px);
				font-family: $headings-font-family;
				color: $text-color;
				display: block;
				@media(max-width: 1000px){
					padding: 0 get-vw(45px);
				}
				@media(max-width: 767px) {
					padding: 0 14px;
				}
				&.dir {
					color: $color-blue;
					font-size: 12px;
					letter-spacing: 0.015em;
					&::before, &::after {
						display: inline-block;
						font-size: 16px;
						line-height: 16px;
						height: 16px;

						vertical-align: middle;
						color: $text-color;
					}
				}
			}
			&.prev {
				span.dir {
					@include iconize("chev-left");
					&::before {
						margin-right: 10px;
					}
				}
			}
			&.next {
				span.dir {
					@include iconize("chev-right","after");
					text-align: right;
					&::after {
						margin-left: 10px;
					}
				}
			}
		}
	}
	.actions a.btn {
		margin-bottom: 10px;
		@media (max-width: 400px){
			width: 100%;
		}
	}
}