comp-calc {
	@include break(nav,desktop){
		display: flex;
		padding: 20px 64px 64px;
	}
	
	> * {
		flex: 1;
		&[controls] {
			flex: 0.7;
		}

		&[position="Right"] {
			order: 1;
		}
	}
	> [controls] {
		flex: 0.7;
		
		header {
			text-align: center;
			> * {
				@extend %heading-divider;
				transition: background-size 0.25s, color 0.25s;
				background-position: center top;
				margin-top: 10px !important;
				cursor: pointer;
				padding: {
					left: 10px;
					right: 10px;
				}
				display: inline-block;
				vertical-align: middle;
				&:not(.selected) {
					background-size: 34px 0px;
					color: $color-gray-dark;
				}
				&.selected {
					color: $color-blue;
				}
			}
		}

		inner-content {
			h4 {
				margin: {
					top: 40px;
					bottom: 24px;
				}
			}
			> div {
				display: flex;
				&:not(:last-child){
					padding-bottom: 18px;
					border-bottom: 1px solid #fff;
				}
				> *:not(.text) {
					flex: 1;
					display: block;
					min-width: 0;
					padding: 0;
					&.range-field{
						flex-grow: 1;
						margin-top: 13px;
					}
					&:not(.range-field){
						flex-basis: 40px;
						flex-grow: 0;
						line-height: 40px;
						font-size: 18px;
						padding: 0;
						min-width: 0;
					}
					&:not(:first-child){
						margin-left: 10px;
					}
					&:not(:last-child){
						margin-right: 10px;
					}
				}
			}

			button {
				font-size: 24px !important;
				background: $color-gray-light !important;
			}
			.result {
				font-weight: 700;
			}
		}
		footer {
			padding-top: 60px;
			@include break(nav,mobile){
				padding-bottom: 60px;
				text-align: center;
			}
		}
	}
	> :not([controls]) {
		//margin: 18px 0;
		background: #fff;
		position: relative;
		@include break(nav,desktop){
			padding: 30px 40px;
		}
		@include break(nav,mobile){
			padding: 30px 0 0 0;
		}
		@include break(nav,mobile){
			padding-bottom: 60px;
			background: #fff linear-gradient($color-gray,$color-gray) center bottom no-repeat;
			background-size: 76% 1px;
		}
		&::after {
			content: "";
			position: absolute;
			@include break(nav,desktop){
				top: 50%;
				left: 0;
			}
			@include break(nav,mobile){
				top: 0;
				left: 50%;
			}
			transform-origin: 50% 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 45px;
			height: 45px;
			background: $color-gray;
		}
		> header {
			@include break(nav,desktop){
				padding-bottom: 42px;
			}
			text-align: center;
			h4 {
				color: $color-blue;
			}
		}
	}
	[results]{
		> div {
			&:first-child {
				border-bottom: 1px solid $color-divider;
				border-top: 1px solid $color-divider;

				div:last-child {
					color: $color-blue;
					h2 {
						font-size: 48px;
					}
				}
			}
		}
		> div {
			text-align: center;
			@include break(nav,desktop){
				padding: 10px;
				display: flex;
				align-items: baseline;
				> * {
					flex: 1;
					&:not(:last-child){
						border-right: 1px solid #8b8d8e;
					}
				}
			}
			@include break(nav,mobile){
				padding: 10px 0;
				> * {
					&:not(:last-child){
						margin-bottom: 36px;
					}
				}
			}
			h2, h6 {
				margin: 7px 0;
				padding: 0;
			}
			h2 {
				background: transparent;
				font-size: 36px;
				
			}
			&:not(:last-of-type) h2{
				font-weight: 100;
			}
		}
		> table {
			@include break(nav,desktop){
				margin-top: 60px;
			}
			width: 100%;
			thead {
				td {
					@include break(nav,desktop){
						padding: 20px 15px;
					}
					@include break(nav,mobile){
						padding: 10px;
					}
					h4 {
						padding: 0;
						margin: 0;
						color: $color-blue;
					}
				}
			}
			tbody {
				tr {
					&:not(:last-child){
						border-bottom: 1px solid $color-gray;
					}
				}
				td {
					vertical-align: top;
					@include break(nav,desktop){
						padding: 20px 15px;
					}
					@include break(nav,mobile){
						font-size: 12px;
						padding: 10px;
					}
					&:first-child {
						color: $color-steel;
					}
					&:not(:first-child){
						font-weight: bold;
					}
				}
			}
		}
	}
}