body > footer {
	@extend %contain;
	@include bgimg("Detroit-Logo-Background-White-blur");
	@extend %sitebgstyle;
	position: relative;
	background-color: $text-color;
	color: $text-color-light;
	z-index: 2;
	a {
		color: $text-color-light !important;
		html:not(.touch) &:hover {
			opacity: 0.8;
		}
	}
	
	> div {
		max-width: $container-large-desktop;
		padding: 0; //0 20px
		box-sizing: content-box;
		@media (max-width: 1120px){
			padding: 0 20px;
		}
		@media (min-width: 768px){
			margin: 50px auto;
		}
		@media (max-width: 767px){
			margin-top: 50px;
		}
		&.primary {
			@media (min-width: 768px){
				display: flex;
				> * {
					flex: 1;
				}
			}
			> nav {
				flex-basis: 60%;
				font-size: 14px;
				@media (min-width: 768px){
					display: flex;
					> div {
						flex: 1;
					}
				}
				@media (max-width: 767px){
					> div:not(:last-child) {
						border-bottom: $divider-border;
						padding-bottom: 15px;
						margin-bottom: 30px;
					}
					> div:last-child {
						margin-bottom: 50px;
					}
				}
				a {
					display: block;
					padding: 5px 0;
				}
			}
			> div.branding {
				flex-basis: 40%;
				position: relative;
				@media (max-width: 767px){
					width: 100%;
				}
				@media (min-width: 768px){
					border-left: $divider-border;
				}
				> div {
					@media (min-width: 768px){
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						width: 76%;
						> div {
							margin: 4px 0 0 78px;
							white-space: nowrap;
						}
					}
					@media (max-width: 1093px) and (min-width: 768px){
						> div {
							white-space: normal;
							margin-left: 66px;
						}
					}
					@media (max-width: 767px){
						text-align: center;
						border-bottom: $divider-border;
						padding-bottom: 30px;
						margin-bottom: 30px;
						img {
							max-width: 220px;
							margin: 0 auto;
						}
						> div {
							margin-top: 10px;
						}
					}
					> div {
						font-size: 12px;
					}
					> div[social] {
						text-align: center;
						margin-left: 0;
						padding-top: 28px;
						> a {
							display: inline-block;
							font-size: 24px;
							line-height: 44px;
							height: 44px;
							width: 44px;
							border: 2px solid $color-white;
							border-radius: 50%;
							margin: 6px;
							text-decoration: none !important;
							background: solidGradient($color-white) no-repeat center center;
							background-size: 110% 0;
							transition: background-size 0.5s;
							&::before {
								transition: color 0.5s;
							}
							html:not(.touch) &:hover {
								background-size: 110% 110%;
								&::before {
									color: $color-black;
								}
							}
						}
					}
				}
			}
		}
		&.secondary {
			padding-top: 12px;
			font-size: 12px;
			@media (min-width: 768px){
				border-top: $divider-border;
				display: flex;
			}
			@media (max-width: 767px){
				padding: {
					top: 0;
					bottom: 50px;
				}
				margin-top: 0;
			}
			> div {
				flex: 1;
				vertical-align: middle;
				@media (min-width: 768px){
					&:not(:first-child){
						border-left: $divider-border;
						padding-left: 25px;
					}
				}
				@media (max-width: 767px){
					margin: 20px 0;
				}
				&.legal {
					flex-basis: 38%;
					//width: 38%;
				}
				&.links {
					flex-basis: 28%;
					//width: 28%;
					//padding-top: 5px;
					a {
						@include bgborder($divider-color-light);
					}
				}
				&.branding {
					flex-basis: 34%;
					//width: 34%;
					//white-space: nowrap;
					@media (max-width: 767px){
						text-align: center;
					}
					img {
						height: 16px;
						width: auto;
					}
					a {
						display: inline-block;
						@media (min-width: 768px){
							margin: 0 15px;
						}
						@media (max-width: 767px){
							margin: 0 5px;
						}
						padding: 5px 0;
						&:first-child {
							margin-left: 0;
						}
						&:last-child {
							margin-right: 0;
							img {
								height: 26px;
							}
						}
					}
					@media(min-width: 768px) and (max-width: 1037px) {
						padding-left: 0;
						a {
							display: block;
							text-align: center;
							margin: 10px 0;
						}
					}
				}
			}
		}
	}
}

[footer-actions] {
	position: relative;
	z-index: 11;
	height: 16vw;

	.comp-background {
		background-position: center;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #707070;
		background-repeat: no-repeat;
		border: none;
	}

	.buttons {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		transition: transform 0.15s, opacity 0.25s, margin 0.25s, padding 0.25s;
		transform: translateY(-50%);
		transform-origin: bottom left;
		opacity: 0;
		margin-bottom: -100px;

		&.visible {
			opacity: 1;
			margin-bottom: 0;
		}

		&.fixed {
			transform-origin: left center;
			position: fixed;
			top: auto;
			bottom: 0;
		}
	}

	@media (min-width: 768px) {
		.buttons {
			&.fixed {
				width: auto;
				text-align: left;
				padding-left: 8.4919472914vw;
			}
		}
	}
}