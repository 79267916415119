comp-background{
	@extend %abs-cover;
	background: no-repeat right top;
	
	[position="Right"] & {
		background-position: left top;
	}

	[crop="None"] &, [crop="Push"] & {
		background-position: center top;
		background-size: contain;
	}
	[crop="Side"] & {
		background-size: auto 100%;
	}
	[crop="All"] & {
		background-position: center top;
		background-size: cover;
	}
	[crop="Push"] & {
		position: static;
		display: block;
		height:0;
		box-sizing: content-box;
	}
    [crop="Auto"] & {
        background-position: center top;
        background-size: auto;
	}
	[vert="Center"] & {
		background-position-y: center;
	}
	[vert="Bottom"] & {
		background-position-y: bottom;
	}

	@include break(nav,desktop){
		[crop]:not([crop="Push"]) & {
			padding-bottom: 0 !important;
		}
	}

	@include break(nav,mobile){
		position: static;
		height: auto;
		display: block;
	}

	
	iframe {
		position: absolute;
		border: none;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: none;
		z-index: 0;
	}
	> .overlay {
		@extend %abs-cover;
		z-index: 1;
		background: rgba(0,0,0,0);
	}
}

[promotion] {
	comp-background {
		@include break(nav,mobile) {
			position: absolute;
			height: 100%;
		}
	}	
}