body > header {
	@include break(nav,desktop){
		min-height: 187px;
		@include bgimg(Dot_Pattern);
		background: {
			repeat: repeat-x;
			position: left bottom;
		}
	}
	> nav {
		position: relative;
		display: block;
		max-width: 1195px;
		margin: 0 auto;
		padding: 12px 20px;
		@include break(nav,desktop){
			padding: 60px 20px;
			display: flex;
		}
		@include break(nav,mobile){
			transition: background 0.25s;
			background: #000 linear-gradient($divider-color-light,$divider-color-light) right 46px no-repeat;
			background-size: 0 1px;
			&.expanded {
				background-size: 100% 1px;
			}
		}
		button.menu-toggle {
			@include break(nav,desktop){
				display: none;
			}
			position: absolute;
			top: 0;
			right: 0;
			width: 54px;
			height: 46px;
			border: 0;
			padding: 0;
			background: transparent;
			outline: none;
			span {
				display: block;
				width: 40%;
				height: 3px;
				margin: 3px auto;
				background: #fff;
				transition: all 0.25s;
				transform-origin: 50% 50%;
			}
		}
		.mobile-search {
			position: absolute;
			&::before {
				color: $color-white;
			}
			@include break(nav,desktop){
				display: none;
			}
		}
		
		> div {
			@include break(nav,desktop){
				flex: 1;
			}
			text-align: center;
			margin: 0 36px;
			@media (max-width: 1235px){
				margin: 0 2.4vw;
			}
			@media (max-width: 1030px){
				margin: 0 1.5vw;
			}
			a {
				display: block;
				@include break(nav,mobile){
					color: $text-color-light !important;
				}
				@include break(nav,desktop){
					display: inline-block;
				}
			}
			&.branding {
				@include break(nav,desktop){
					flex-grow: 0;
					flex-basis: 14%;
					text-align: left;
					margin-left: 0;
					svg{
						width: 160px;
						height: 32px;
					}
					position: absolute;
					top: 19px;
					left: 20px;
				}
				@include break(nav,mobile){
					display: inline-block;
					svg {
						height: 20px;
						width: 101px;
						.cls-1 {
							fill: $text-color-light;
						}
					}
				}
			}
			&.primary {
				font-size: 18px;
				font-weight: bold;
				transition: padding 0.25s;
				@include break(nav,desktop){
					margin: {
						left: 0;
						right: 0;
					};
					display: flex;
					justify-content: space-between;
				}
				a {
					padding: 0 15px;
					@include break(nav,desktop){
						color: #000;
						flex-grow: 0;
						text-align: right;
						&:last-child {
							padding-right: 0;

						}
						&:nth-child(2) {
							padding-left: 0;
						}
					}
					@media (max-width: 1235px){
						padding: 0 get-vw(10px,1235px);
					}
					@include break(nav,desktop){
						&.icon-location {
							display: none !important;
						}
					}
					@include break(nav,mobile){
						&.icon-location::before {
							display: inline-block;
							margin-right: 10px;
						}
					}

					&.active {
						color: $color-blue;
					}
				}
			}
			&.secondary {
				@include break(nav,desktop){
					position: absolute;
					top: 19px;
					right: 20px;
					text-align: right;
					margin-right: 0;
					font-size: .77777em;
					font-weight: $font-weight-demi;
				}
				text-transform: uppercase;
				font-family: $headings-font-family;
				a {
					padding: 0 15px;

					@include break(nav,desktop){
						padding: 0.11111em 20px;
						color: #525354;
						@media (max-width: 1235px){
							padding: 0.11111em get-vw(13px,1235px);
						}
						@media (max-width: 1030px){
							padding: 0.11111em get-vw(8px,1235px);
						}
						&:last-child{
							padding-right: 0;
						}
					}
					@include break(nav,mobile){
						&.icon-location {
							display: none !important;
						}
					}
					&::before {
						display: inline-block;
						vertical-align: middle;
						margin-right: 10px;
					}
				}
			}			
		}
		@include break(nav,mobile){
			.primary, .secondary {
				a {
					transition: max-height 1s;
					max-height: 2em;
					overflow: hidden;
				}

			}
			&:not(.expanded){
				.primary, .secondary {
					a {
						max-height: 0;
					}
				}
			}
			&.expanded {
				.primary {
					padding-top: 20px;
				}
				button.menu-toggle span{
					&:not(:first-child):not(:last-child){
						opacity: 0;
					}
					&:first-child {
						transform-origin: 0% 30%;
						transform: rotate(45deg);
					}
					&:last-child {
						transform-origin: 17% 100%;
						transform: rotate(-45deg) translate(0, 0%);
					}
				}
			}
		}
	}
}

// test css
.search-bar {
	text-align: right;
	padding-right: get-vw(20px);
	//display: none !important;
	@media (min-width: 971px){
		display: inline-block;
		position: relative;
		z-index:1;
	}
	@include break(nav,mobile){
		display: none;
	}

	> div {
		top: 0;
		right: 0;
		width: 0;
		//overflow: hidden;
		background: $btn-default-bg;
		height: 26px;
		display: flex;
		transition: all 0.25s;
		@media (min-width: 971px){
			position: absolute;
		}
		&::before {
			margin-left: 10px;
			transform: translateY(35%);
		}
			
		form {
			flex-grow: 1;
			padding: 0 10px;
			height: 100%;
			background: transparent;
			border: 0;
			box-shadow: none;
			margin: 0;
			input {
				font-size: 16px;
				box-shadow: none;
				margin: 0 !important;
				padding: 0 !important;
				background: rgba(0,0,0,0) !important;
				color: $color-black;
				height: 27px !important;
				line-height: 29px;
				width: 100%;
				border: none;
				display: block;	
				outline: none;
				&::placeholder {
					border-bottom: 1px solid $color-blue;
					color: #e2e6e9;
					font-size: 14px;
					text-indent: 0;
					text-transform: uppercase;
				}
			}

		}
		td {
			height: 100%;
			margin: 0 !important;
			padding: 0 !important;
			display: block;
		}
		table {
			margin: 0;
			width: 100%;
		}

		@include break(nav,desktop){
			width: 200%;
			// background: pink;
			> div > div{
				height: 100%;
				* {
					height: inherit;
				}
			}
		}
	}

	.search_autocomplete {
		z-index: 1;
		border: 1px solid #000;
		background: #fff;
		text-align: left;
		padding:0;
		li {
			margin: 0 !important;
			padding: 5px 10px;
		}
	}

}
body > header > nav > div.search-mobile {
	display: none;
	a {
		&:hover, 
		&:focus {
			text-decoration: none;
		}
	}
	@include break(nav,mobile) {
		display: inline-block;
		margin: 0;
	}

	> div {
		background: $color-black;
		color: $color-white;
		position: absolute;
		height: 54px;
		left: 0;
		overflow: hidden;
		> div {
			flex: 1;
			flex-grow: 1;
		}
		form {
			input {
				color: $color-white;
				height: 54px !important;
				line-height: 56px
			}
		}
	}

	button.close {
		background: $color-black;
		border: 0;
		width: 50px;
		flex-grow: 0;
		&:focus {
			outline: 0;
		}
	}

	&.search-active {
		// width: 100%;
		> div {
			width: 100%;
			z-index: 1000;
		}
	}
}

@include break(nav,mobile) {
	.search-mobile {
		width: 22%;
		text-align: left;
	}

	.branding {
		width: 50%;
	}
}

// end text css