super-video {
	@extend %block;
	@extend %top-level-component;
	position: relative;

	&[theme="White"] {
		@include comp-padding(vert,0);
	}

	comp-video[jwpid] {
		@extend %abs-cover;
		position: relative;
		@include iconize(play-circle,after);
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 100px;
			opacity: 0.75;
			transition: opacity 0.2s;
		}
		&[jwpid="+"]::after{
			display: none;
		}
		&:not([jwpid="+"]){
			cursor: pointer;
			html:not(.touch) &:hover {
				&::after {
					opacity: 1;
				}
			}
		}
		comp-background {
			@extend %abs-cover;
			background: {
				size: cover;
				position: center top;
			}
		}
	}

	inner-content {
		@include max-center(true);
		> [role="button"] {
			margin: 5px;
		}
	}
	h1,h2 {
		@extend %heading-center;
	}

}