//** Breakpoints
$breakpoints: (
	nav: (
		mobile: 970px,
		desktop: 971px
	),
	tab: (
		mobile: 767px,
		desktop: 768px
	),
	search: (
		mobile: 480px,
		desktop: 481px
	)
);

$smallest-screen: 320px;

//** Container
$container-max: 1110px;
$container-side-margin: 10px;

//** Colors
$color-divider:			#e2e6e9;
$color-gray:			#e9ecef;
$color-gray-light:		#d1d4d7;
$color-gray-dark: 		#8b8d8e;
$color-steel:			#8ea0ac;
$color-blue:			#5482ab;
$color-black:			#000;
$color-white:			#fff;

//** Background color for `<body>`.
$body-bg:               $color-white;

//** Global Text Color
$text-color:            $color-black;
$text-color-light:      $color-white;

//** Global textual link color
$link-color: $color-blue;
$link-hover-color: darken($link-color,10%);

//== Typography
$font-family-sans-serif:  'Roboto', sans-serif;
$headings-font-family:    'Roboto Condensed', sans-serif;
$font-weight-light:		  300;
$font-weight-medium:	  400;
$font-weight-demi:		  700;
$font-size-base:          18px;
$line-height-base:        1.666667;

$font-size-h1:            60px;
$font-size-h2:            30px;
$font-size-h3:            24px;
$font-size-h4:            20px;
$font-size-h5:            $font-size-base !default;
$font-size-h6:            14px;

$heading-responsive: (
	h1: (
		base: $font-size-h1,
		small: 36px
	),
	h2: (
		base: $font-size-h2,
		small: 26.89px
	),
	h4: (
		base: $font-size-h4,
		small: 14px
	)
);

$headings-color:          $text-color;
$headings-color-light:    $link-color;

$container-large-desktop: 1070px;

$content-center-max: 650px;

// Other Colors
$divider-color:			$link-color;
$divider-color-light:	rgba(235,235,235,0.25);
$divider-color-gray: 	#ededed;
$divider-color-dark: 	transparentize(#3b3b3b,0.9);

$divider-border: 1px solid $divider-color-light;
$divider-border-dark: 1px solid $divider-color-dark;
$loader-color: $link-color;

$component-padding: (
	side: 20px,
	vert: 5%
);

$themes: (
	White: (
		background: $color-white
	),
	Gray: (
		background: $color-gray
	),
	Steel: (
		background: $color-steel,
		headings: $text-color-light,
		copy: $text-color-light,
		link: $text-color-light
	),
	Blue: (
		background: $color-blue,
		headings: $text-color-light,
		copy: $text-color-light,
		link: $text-color-light,
		divider: $divider-color-light,
		button-border: $text-color-light,
		button-background: transparent
	),
	Black: (
		background: $color-black,
		headings: $text-color-light,
		headings-alt: $headings-color-light,
		copy: $text-color-light,
		divider: $divider-color-light
	)
);

$font-icons: (
	"bullet": "\61",
	"images": "\62",
	"doc2": "\63",
	"expand": "\64",
	"link": "\65",
	"search": "\66",
	"location": "\67",
	"account": "\68",
	"image": "\69",
	"doc1": "\6a",
	"tag": "\6b",
	"shock": "\6c",
	"tools": "\6d",
	"chev-right": "\6e",
	"chev-left": "\6f",
	"chev-up": "\70",
	"chev-down": "\71",
	"google-plus": "\72",
	"facebook": "\73",
	"twitter": "\74",
	"close": "\75",
	"route": "\77",
	"geo": "\76",
	"check": "\78",
	"filter": "\79",
	"youtube": "\7a",
	"instagram": "\41",
	"linkedin": "\42",
	"envelope": "\43",
	"resources": "\44",
	"play-circle": "\45"
)