#cludo-search-results {
	max-width: 940px;
	padding: 50px 20px;
	margin: 0 auto;
	@include break(search,mobile){
		.cludo-c-3 {
			display: none;
		}
		.cludo-c-9 {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}
	.search-filters {
		font-family: $headings-font-family;
	}
	.search-result-count {
		padding: 0 0 18px 0;
		color: #6F87A6;
		font-family: $headings-font-family;
	}
	.search-results {
		border-top: 1px solid #e9e9e9;
		padding-top: 30px;
		h2 a {
			color: #3b3b3b;
		}
		h2 {
			background: none;
			margin-top: 0;
		}
		.search-results-item {
			
			&:not(:first-of-type){
				border-top: 1px solid #e9e9e9;
				margin-top: 30px;
				padding-top: 30px;
			}
			* {
				word-wrap:break-word;
			}
		}
		> nav {
			font-size: 28px;
			position: relative;
			> a {
				display: none;
			}
			ul {
				text-align: center;
				display: flex;
				justify-content: center;
			}
			li {
				font-size: 16px;
				font-family: $headings-font-family;
				letter-spacing: 0.1em;
				font-weight: $font-weight-demi;
				line-height: 40px;
				width: 40px;
				text-align: center;
				height: 40px;
				color: #8b8d8e;
				display: inline-block;
				padding: 0;

				@media(max-width: 374px){
					&:not([class]){
						display: none;
					}
				}

				&.active {
					background: $color-blue;
					border-radius: 50%;
					cursor: default;
					color: $color-white;
					&:hover {
						text-decoration: none;
					}
				}
				&.previous, &.next  {
					width: auto;
					@include break(search,desktop){
						display: none;
						position: absolute;	
					}
					margin: auto;
					a {
						color: $text-color;
						&::after, &::before {
							color: $color-blue;
							font-size: 12px;
							display: inline-block;
							vertical-align: middle;
						}	

					}
					
				}
				&.previous {
					justify-self: flex-start;
					left: 0;
					margin-left: 0;
					a::after {
						content: "PREVIOUS";
						margin-left: 16px;
					}
				}
				&.next {
					justify-self: flex-end;
					right: 0;
					margin-right: 0;
					a::before {
						content: "NEXT";
						margin-right: 16px;
					}
				}
			}
		}
	}
}


// .gsc-control-wrapper-cse {
// 	@include break(tab,mobile) {
// 		padding-top: 0;
// 		.gsc-refinementsArea {
// 			display: none;
// 		}
// 		.gsc-result-info-container, .gsc-orderby-container {
// 			display: block;
// 			width: 100%;
// 			text-align: left;
// 			padding: 5px 0;
// 		}
// 	}

// 	// Search Form
// 	.gsc-search-box {
// 		td {
// 			vertical-align: top;
// 		}
// 	}

// 	.gsc-input, .gsc-search-button {
// 		@include break(search,mobile) {
// 			display: block;
// 			width: 100%;
// 		}
// 	}

// 	.gsc-search-button {
// 		@include break(search,mobile) {
// 			margin: 10px auto 0;
// 		}
// 	}

// 	.gsc-search-box-tools .gsc-search-box .gsc-input {
// 		padding-right: 0;
// 	}

// 	.gsc-input-box {
// 		height: auto;
// 		position: relative;
// 		@include iconize(search);
// 		&::before {
// 			position: absolute;
// 			left: 0;
// 			top: 50%;
// 			transform: translateY(-50%);
// 			width: 55px;
// 			text-align: center;
// 			font-size: 22px;
// 			color: $color-blue;
// 		}
// 		input {
// 			height: 44px !important;
// 			font-family: $headings-font-family;
// 			font-size: 24px;
// 		}
// 		.gsib_a {
// 			padding-left: 55px;
// 		}
// 		.gsst_b {
// 			display: none;
// 		}
// 	}

// 	.gsc-search-button {
// 		width: 230px;
// 		cursor: pointer;
// 		input {
// 			height: 0;
// 			padding: 0;
// 			background: transparent;
// 			border: 0;
// 		}
// 		&::before {
// 			@extend a[role="button"].block;
// 			content: "Search";
// 			display: block;
// 			height: 49px;
// 			margin-left: 2px;
// 			line-height: 49px;
// 			padding: 0;
// 		}
// 	}

// 	// Global
// 	.gsc-above-wrapper-area-container, .gs-title, .gsc-cursor-box {
// 		font-family: $headings-font-family;
// 	}

// 	// Heading
// 	.gsc-tabsArea, .gcsc-branding {
// 		//display: none;
// 	}
// 	.gsc-above-wrapper-area {
// 		margin: {
// 			// left: 8px;
// 			// right: 8px;
// 			bottom: 10px;
// 		}
// 	}
// 	.gsc-result-info-container, .gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
// 		padding: {
// 			left: 0;
// 			right: 0;
// 		}
// 	}
// 	.gsc-result-info {
// 		color: $color-blue;
// 		letter-spacing: 0.1em;
// 		font-weight: $font-weight-demi;
// 		font-size: 14px;
// 		text-transform: uppercase;
// 		padding-left: 0;
// 	}
// 	.gsc-selected-option-container, .gsc-orderby-label {
// 		font-size: 16px;
// 		background: transparent;
// 		border: 0;
// 		box-shadow: none;
// 		//color: $color-secondary;
// 		font-weight: normal;
// 	}
// 	.gsc-orderby-label {
// 		color: #8c9091;
// 	}
// 	.gsc-option-menu-container {
// 		cursor: pointer;
// 	}
// 	.gsc-control-cse .gsc-option-selector {
// 		top: 8px;
// 	}
// 	.gsc-refinementsArea {
// 		border-bottom: 0;
// 	}

// 	// Results

// 	.gsc-results {
// 		.gcsc-branding {
// 			display: none;
// 		}
// 	}

// 	// .gsc-table-cell-snippet-close, .gsc-table-cell-snippet-open {
// 	// 	padding-left: 8px;
// 	// }
// 	.gs-title {
// 		color: $text-color !important;
// 		font-size: 24px !important;
// 		text-transform: uppercase;
// 		font-weight: 700;
// 		text-decoration: none !important;
// 		letter-spacing: 0.015em;
// 		height: auto !important;
// 		b {
// 			font-size: inherit !important;
// 			color: inherit !important;
// 			text-decoration: inherit !important;
// 		}
// 		&:hover {
// 			text-decoration: underline !important;
// 		}
// 		@media(max-width: 767px){
// 			font-size: 24px !important;
// 			height: auto !important;
// 			word-wrap: break-word;
// 		}
// 	}
// 	.gsc-url-top {
// 		font-size: 16px;
// 		margin-top: 0px;
// 		margin-bottom: 6px;
// 		* {
// 			color: $color-black !important;
// 			text-transform: uppercase;
// 			font-family: $headings-font-family;
// 		}
		
// 	}
// 	.gs-snippet {
// 		font-size: 16px !important;
// 		color: $text-color !important;
// 		line-height: 1.5;
// 		* {
// 			font-size: inherit !important;
// 			color: inherit !important;
// 		}
// 	}
// 	.gs-image-box.gs-web-image-box.gs-web-image-box-landscape {
// 		width: 210px;
// 		img {
// 			max-width: 200px;
// 			max-height: 142px;
// 		}
// 		@media(max-width: 767px){
// 			width: 120px;
// 			img {
// 				max-width: 110px;
// 				max-height: 78px;
// 			}
// 		}
// 	}

// 	.gsc-result::after {
// 		content: "";
// 		display: block !important;
// 		visibility: visible !important;
// 		border-bottom: 1px solid #E9E9E9 !important;
// 		margin: 26px 0 0px 0;
// 	}

// 	// Footer 
// 	.gsc-cursor-box {
// 		font-size: 28px;
// 		text-align: center;
// 		.gsc-cursor {
// 			.gsc-cursor-page {

// 				font-size: 16px;
// 				font-family: $headings-font-family;
// 				letter-spacing: 0.1em;
// 				font-weight: $font-weight-demi;
// 				line-height: 40px;
// 				width: 40px;
// 				text-align: center;
// 				height: 40px;
// 				color: #8b8d8e;
// 				display: inline-block;
// 				&.gsc-cursor-current-page {
// 					//color: $color-secondary;
// 					background: $color-blue;
// 					border-radius: 50%;
// 					cursor: default;
// 					color: $color-white;
// 					&:hover {
// 						text-decoration: none;
// 					}
// 				}
// 			}
// 		}
		
// 	}

// 	.gs-no-results-result {
// 		.gs-snippet {
// 			border: 0;
// 			//background: $color-secondary;
// 			//color: $text-color-light !important;
// 			font-weight: 700 !important;
// 			font-size: 24px !important;
// 			text-transform: uppercase;
// 			text-align: center;
// 			padding: 10px;
// 			font-family: $headings-font-family;
// 		}
// 	}

// }