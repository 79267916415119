comp-two-col{
	&[contain] {
		@extend %top-level-component;
	}
	@include break(tab,mobile){
		display: block;
	}
	@include break(tab,desktop){
		display: flex;
	}
	> *{
		flex: 1 100%;
		@include break(tab,desktop){
			max-width: 50%;
		}
	}
}