comp-press{
	@extend %top-level-component;
	@extend %block;
	padding-top: 0 !important;
	.btn {
		@extend %block;
		text-align: left;
		margin-bottom: 10px;
	}
	nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: {
			left: auto;
			right: auto;
		}
		input {
			@extend %block;
		}
	}
	comp-cards > div {
		padding: {
			left: 0;
			right: 0;
		}
		.btn.load-more {
			opacity: 1;
			display: block;
			margin: 30px auto 0 auto;
		}
		&.loading{
			&::after {
				content: "";
				display: block;
				font-size: 20px;
				margin: 100px auto;
				width: 1em;
				height: 1em;
				border-radius: 50%;
				position: relative;
				text-indent: -9999em;
				animation: pressLoadingAnim 1.3s infinite linear;
				transform: translateZ(0);
			}
			
		}
		&.loading, &.no-more{
			.btn.load-more {
				display: none !important;
			}
		}
		&.none:not(.loading) {
			&::after {
				@extend h3;
				content: attr(no-results);
			}
		}
	}
	[data-node-name] {
		position: relative;
		cursor: pointer;
		figure::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			height: 1em;
			width: 0%;
			transition: width 1s;
			background: $divider-color;
		}
		&.loading {
			figure::after {
				width: 100%;
			}
		}
	}
}

@keyframes pressLoadingAnim {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em $loader-color, 2em -2em 0 0em $loader-color, 3em 0 0 -1em $loader-color, 2em 2em 0 -1em $loader-color, 0 3em 0 -1em $loader-color, -2em 2em 0 -1em $loader-color, -3em 0 0 -1em $loader-color, -2em -2em 0 0 $loader-color;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 $loader-color, 2em -2em 0 0.2em $loader-color, 3em 0 0 0 $loader-color, 2em 2em 0 -1em $loader-color, 0 3em 0 -1em $loader-color, -2em 2em 0 -1em $loader-color, -3em 0 0 -1em $loader-color, -2em -2em 0 -1em $loader-color;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em $loader-color, 2em -2em 0 0 $loader-color, 3em 0 0 0.2em $loader-color, 2em 2em 0 0 $loader-color, 0 3em 0 -1em $loader-color, -2em 2em 0 -1em $loader-color, -3em 0 0 -1em $loader-color, -2em -2em 0 -1em $loader-color;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em $loader-color, 2em -2em 0 -1em $loader-color, 3em 0em 0 0 $loader-color, 2em 2em 0 0.2em $loader-color, 0 3em 0 0em $loader-color, -2em 2em 0 -1em $loader-color, -3em 0em 0 -1em $loader-color, -2em -2em 0 -1em $loader-color;
  }
  50% {
    box-shadow: 0 -3em 0 -1em $loader-color, 2em -2em 0 -1em $loader-color, 3em 0 0 -1em $loader-color, 2em 2em 0 0em $loader-color, 0 3em 0 0.2em $loader-color, -2em 2em 0 0 $loader-color, -3em 0em 0 -1em $loader-color, -2em -2em 0 -1em $loader-color;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em $loader-color, 2em -2em 0 -1em $loader-color, 3em 0 0 -1em $loader-color, 2em 2em 0 -1em $loader-color, 0 3em 0 0 $loader-color, -2em 2em 0 0.2em $loader-color, -3em 0 0 0 $loader-color, -2em -2em 0 -1em $loader-color;
  }
  75% {
    box-shadow: 0em -3em 0 -1em $loader-color, 2em -2em 0 -1em $loader-color, 3em 0em 0 -1em $loader-color, 2em 2em 0 -1em $loader-color, 0 3em 0 -1em $loader-color, -2em 2em 0 0 $loader-color, -3em 0em 0 0.2em $loader-color, -2em -2em 0 0 $loader-color;
  }
  87.5% {
    box-shadow: 0em -3em 0 0 $loader-color, 2em -2em 0 -1em $loader-color, 3em 0 0 -1em $loader-color, 2em 2em 0 -1em $loader-color, 0 3em 0 -1em $loader-color, -2em 2em 0 0 $loader-color, -3em 0em 0 0 $loader-color, -2em -2em 0 0.2em $loader-color;
  }
}
