comp-models {
	@extend %block;
	@extend %top-level-component;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
	> inner-content {
		@include max-center(true);
		> h2 {
			@extend %heading-center;
		}
	}
	[models-container] {
		@include break(tab,desktop){
			display: flex;
		}
		text-align: left;
		margin: 38px 0;
		> inner-content {
			&[models="0"] {
				display: none;
			}
			@include break(tab,desktop){
				flex: 1;
				&:not(:first-child) {
					padding-left: 30px; 
				}
				&:not(:last-child){
					padding-right: 30px;
				}
				&:last-child:not(:first-child) {
					border-left: 1px solid $divider-color-gray;
				}
			}
			@include break(tab,mobile){
				&:not(:first-child) {
					margin-top: 30px;
				}
			}
			> a {
				img {
					@include break(tab,desktop){
						height: 20px;
						display: inline-block;
						margin-right: 22px; 
						vertical-align: middle;
					}
					@include break(tab,mobile){
						height: 37px;
						display: block;
						margin: 0 auto 10px auto;
					}
				}
				@include break(tab,mobile){
					text-align: center;
					display: block;
				}
			}
			> div {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				@include break(tab,desktop){
					margin: 0 -15px;
				}
				@include break(tab,mobile){
					margin: 0 -5px;
				}
				> [card] {
					flex-basis: 33.3%;
					max-width: 33.3%;
					padding: 30px 15px 0 15px;
					@include break(tab,mobile){
						padding: {
							left: 5px;
							right: 5px;
						}
					}
				}
			}
		}
	}
}