comp-showcase {
	@extend %block;
	@extend %top-level-component;
	@include comp-padding(side);
	@include comp-padding(vert,0,!important);
	// Add priority

	body & {
		margin-top: 10%;
	}

	// body &[theme="White"] {
	// 	margin-top: 0;
	// 	>inner-content {
	// 		padding: 0 3.4%;
	// 	}
	// } 
	
	text-align: center;

	@include headings(1,2){
		background-position: center top;
	}
	
	> img {
		margin: -9.5vw auto 0 auto;
		position: relative;
	}
	>inner-content {
		padding: 3.4% 3.4% 50px 3.4%;
		@media (max-width: 600px){
				text-align: left;
		}

		> comp-specs {
			margin-bottom: 27px;
			@include break(nav,desktop){
				display: flex;
				> * {
					flex: 1;
					&:not(:first-child){
						//section {
							border-left: 1px solid #8b8d8e;
							padding-left: 15px;
						//}
						
					}
					&:not(:last-child){
						//section {
							border-right: 1px solid #8b8d8e;
							margin-right: -1px;
							padding-right: 15px;
						//}
					}
				}
			}
			i {
				display: block;
				width: 66px;
				height: 66px;
				border: 4px solid #828284;
				border-radius: 50%;
				margin: 0 auto 17px auto;
				background: no-repeat center center;
				background-size: cover;
			}
			h2 {
				background: none !important;
				margin-top: 0 !important;
				@include break(nav,desktop){
					padding: 0;
					margin-bottom: 25px;
				}
				@include break(nav,mobile){
					margin-bottom: 5px;
				}
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-light;
			}
			h4 {
				@include break(nav,desktop){
					margin-bottom: 0;
				}
				color: $link-color;
			}
			p {
				margin-top: 1em;
			}
		}
		> p {
			@include max-center();
			margin: {
				top: 30px;
				//bottom: 0;
			}
			//padding-bottom: 12.3%;
		}
		.disclaimer {
			font-size: 0.66em;
			&::before {
				content: "*";
			}
		}
	}
}