$image-path: '../images' !default;
@function imagePath($name,$ext:png){
	@return "#{$image-path}/#{$name}.#{$ext}"
}
@mixin bgimg($name, $size:false, $ext:png){
	background: {
		image: url(imagePath($name,$ext));
		@if($size){
			size: $size;
		}
	}		
}

@function strip-units($value) {
		@return $value / ($value * 0 + 1);
}

@function get-vw($target,$ref: 2390px) { 
	$vw-context: $ref*.01;
	@return ($target/$vw-context) * 1vw;
}

@mixin max-center($text:false,$width:$content-center-max){
	margin: {
		left: auto;
		right: auto;
	}
	max-width: $width;
	@if $text == true{
		text-align: center;
	}
}

// Breakpoints
// =============================================================================

@mixin break($type,$size) {
	@if $size == desktop {
		@media (min-width: map-get(map-get($breakpoints, $type), $size)) {
			@content;
		}
	}

	@else if $size == mobile {
		@media (max-width: map-get(map-get($breakpoints, $type), $size)) {
			@content;
		}
	}
}

@mixin responsiveExtend($name){
	// Default Extend
	%#{$name} {
		@content
	}

	// Extend for every breakpoint in the breakpoints map. name-type-breakpoint, or for default name-breakpoint
	@each $label, $map in $breakpoints {
		@each $type, $map in map-get($breakpoints,$label) {
			$section: if($label == default,"",-#{$label});
			%#{$name}#{$section}-#{$type} {
				@include break($type,$label){
					@content
				}
			}
		}
	}
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin headings($from: 1, $to: 6){
	$sel: ();
	@for $i from $from through $to{
		$sel: append($sel,"h" + $i,comma);
		$sel: append($sel,".heading-" + $i,comma);
	}
	#{$sel}{
		@content
	}
}

@function solidGradient($color){
	@return linear-gradient(to bottom, $color, $color)
}

@mixin bgborder($color: #fff){
	background: solidGradient($color) no-repeat center bottom;
	background-size: 100% 1px;
}

@mixin comp-padding($dir,$override:false,$important:null){
	$amt: map-get($component-padding, $dir);
	@if $override != false {
		$amt: $override;
	}
	padding: {
		@if $dir == side {
			left: $amt $important;
			right: $amt $important;
		}
		@else if $dir == vert {
			top: $amt $important;
			bottom: $amt $important;
		}
	}
}

// @mixin selectStyle($textcolor,$bgcolor,$icon,$iconbg,$iconsize,$iconpad){
// 	@extend %select-base;

// }