comp-takeover {
	@extend %abs-cover;
	z-index: 1;
	//display: none;
	background: rgba(0,0,0,0.5);
	position: fixed;
	display: flex;
	padding: 28px;
	comp-container {
		background-color: #dbe0e4;
		flex: 1;
		padding: 0;
		display: flex !important;
		> comp-background {
			@extend %mobile-swap;
			position: absolute;
			height: 100%;
			background-size: contain;
			background-position: center;
			// background-position: center 11.2vw;
			padding: 0 !important;
			// @include break(nav,mobile){
			// 	background-position: center bottom;
			// }

			// @media (max-height: 640px) and (orientation: landscape) {
			// 	display: none;
			// }
		}
		> div {
			position: relative;
			padding: 7.3vw;
			@include break(nav,mobile){
				padding: 70px 15px 0 15px;
			}
			flex: 1;
			> a[role="button"]{
				position: absolute;
				bottom: 50px;
				left: 50%;
				transform: translateX(-50%);
				@include break(nav,mobile){
					bottom: 35px;
				}
			}
		}
		h2 {
			margin-top: 0 !important;
			padding-top: 0 !important;
			background: none !important;
			@include break(nav,mobile){
				font-size: 34px;
			}
			@media (max-height: 640px) and (orientation: landscape) {
				font-size: 30px !important;
			}
		}
		h4 {
			font-size: 14px;
			@include break(nav,mobile){
				font-size: 16px;
			}
		}
		h2, h4 {
			text-align: center;
		}
		comp-video {
			max-width: 576px;
			margin: {
				top: 60px;
				left: auto;
				right: auto;
			}
			@include break(nav,mobile){
				margin-top: 34px;
			}
			@media (max-height: 640px) and (orientation: landscape) {
				display: none !important;
			}
		}

		button.close:not(.menu-toggle):not(.text) {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			width: 70px;
			height: 70px;
			text-align: center;
			line-height: 70px;
			border-radius: 0;
			min-width: 0;
			font-size: 18px;
			@include iconize(close);
			@include break(nav,mobile){
				width: 52px;
				height: 52px;
				line-height: 52px;
				font-size: 13px;
			}
		}
	}
}