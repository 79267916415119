%input-style {
	background: #f5f6f6;
	border-width: 0;
	border-bottom: 1px solid $color-blue;

	&[disabled] {
		border-bottom-color: $color-gray;
		background: repeating-linear-gradient(
			45deg,
			transparent,
			transparent 3px,
			rgba(255,255,255,0.8) 3px,
			rgba(255,255,255,0.8) 6px
		), #f5f6f6;
		cursor: not-allowed;
	}
}

form {
	@extend %top-level-component;
	@include comp-padding(vert,0,!important);

	&[paged] {
		&.no-prev {
			[action="prev"] {
				display: none;
			}
		}

		&.no-next {
			[action="next"] {
				display: none;
			}
		}

		&:not(.no-next) {
			[type="submit"] {
				display: none;
			}
		}
	}

	&[filters] {
		@extend %top-level-component;
		text-align: center;

		select {
			@extend %select-arrow;
			margin-bottom: 15px;
			min-width: 296px;
		}
	}

	> comp-callout {
		padding: {
			bottom: 0;
		}

		&:first-child {
			padding-top: 0;
		}
	}

	> fieldset {
		padding: 45px 0;
		margin: 0 auto;
		border: 0;

		&:not([twocol]) {
			max-width: 326px;
		}

		&[twocol] {
			max-width: 674px;

			> [row] {
				display: flex;

				> * {
					flex: 1;

					&:first-child {
						margin-right: 10px;
					}

					&:last-child {
						margin-left: 10px;
					}
				}
			}
		}

		+ * {
			border-top: 1px solid $divider-color-gray;
		}

		&[disabled] {
			display: none;
		}
	}

	> footer {
		padding: 30px 0;
		text-align: center;

		.formDisclaimer {
			text-align: left;

			em {
				font-style: italic;
			}
		}

		p {
			max-width: 500px;

			margin: {
				left: auto;
				right: auto;
				top: 40px;
			}
		}
	}

	@include break(tab,desktop) {
		> input[type="hidden"] + fieldset {
			padding-top: 0;
		}
	}

	[field] {
		margin-bottom: 24px;

		> *:not([type="checkbox"]):not([type="radio"]) {
			display: block;
			width: 100%;
		}

		&.invalid {
			label:first-child {
				color: Red;
			}
		}

		label {
			&:first-child {
				font-size: 18px;
				//line-height: 36px;
				//height: 36px;
				color: $color-blue;
				transition: font-size 0.25s;
			}

			sup {
				font-size: 18px;
				line-height: inherit;
				top: -10px;
				display: inline-block;
				vertical-align: middle;
			}

			+ {
				textarea, input, select {
					@extend %input-style;
				}
			}

			&[def] {
				font-family: $headings-font-family;
				font-size: 14px;
				color: $color-steel;
			}
		}

		&.filled {
			label {
				font-size: 12px;
			}
		}

		select {
			//@extend %input-style;
			@extend %select-arrow;
			// height: 33px;
			background-color: #f5f6f6;

			option {
				color: #444;
			}
		}

		textarea {
			&[maxlength] {
				min-height: 250px;
			}
		}

		input {
			&[type="checkbox"], &[type="radio"] {
				display: none;

				~ label {
					padding-left: 34px;
				}

				&:checked {
					+ label:not([def])::before {
						border-style: double;
						border-width: 10px;
					}
				}

				+ label {
					&:not([def]) {
						font: {
							size: 18px;
							family: $headings-font-family;
							weight: $font-weight-demi;
						}

						color: $color-blue;
						text-transform: uppercase;
						position: relative;

						&::before {
							content: "";
							display: block;
							position: absolute;
							top: 6px;
							left: 0;
							width: 20px;
							height: 20px;
							border: 2px solid $color-blue;
							transition: border 0.25s;
						}
					}
				}
			}

			&[type="radio"] {
				+ label::before {
					border-radius: 50%;
				}
			}

			&[type="file"] {
				line-height: 1;
				padding: 10px;
			}
		}
	}

	[scrolling] {
		border: 1px solid #ccc;
		box-shadow: inset 0 0 8px rgba(0,0,0,0.15);
		padding: 20px 10%;
		max-height: 220px;
		overflow: auto;

		h2 {
			background-position: center 0;
		}
	}

	.errors {
		max-width: 326px;
		margin: 0 auto;

		p {
			margin-bottom: 0;
			color: Red;
			font-weight: bold;

			&:first-child {
				margin-top: 20px;
			}

			&:last-child {
				margin-bottom: 20px;
			}
		}
	}

	footer {
		input + p {
			margin-top: 2em;
		}

		nav {
			display: inline;

			> * {
				margin: 0 20px;
			}
		}
	}
}

.processing {
	background: url('/images/loading.svg') no-repeat center !important;
	background-size: cover !important;
	cursor: wait !important;
}

input[type="submit"] {
	&.processing {
		color: #5482ab;
		box-shadow: inset 0 0 0 3px #8ea0ac;
	}
}