
month-picker {
    position:relative;
    z-index:1;
    > .cover {
        @extend %abs-cover;
        position: fixed;
        z-index: 1;
    }
    > input {
        cursor: pointer;
    }
    > section {
        display: flex;
        flex-wrap: wrap;
        width: 100%; 
        position:absolute;
        top:0;
        left:0;
        background-color: $color-white;
        text-align: center;
        padding:6px;
        box-shadow: 0 0 10px rgba(68,68,68,0.13);
        z-index: 2;
        > select {
            flex-basis: 50%;
            margin: 2px;
            + button {
                flex-basis: calc(50% - 8px);
                margin: 2px;
                min-width: 0 !important;
            }
        }
        > label {
            display: flex;
            font-size: .9em;
            flex-basis: 33%;
            max-width: 33%;
            justify-content: stretch;
            align-items: stretch;
            cursor: pointer;
        }
        input[type=radio] {
            opacity:0;
            width: 0;
            height: 0;
            & + span {
                background: $color-blue;
                color: $text-color-light;
                display: block;
                font-size: 11px;
                font-weight: bold;
                height: 28px;
                line-height: 28px;
                outline: 0 none;
                margin: 1px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:checked + span {
                background: $color-steel;
            }
        }
    }
    &:not(.active) {
        > section, > .cover {
            display: none;
        }
    }
}