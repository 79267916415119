nav[icons] {
	@extend %top-level-component;
	@extend %tab-nav;
	@include comp-padding(vert,0,!important);
	@include break(nav,mobile){
		margin: {
			left: 0 !important;
			right: 0 !important;
		}
	}
	
	body > header + & {
		@include break(nav,desktop){
			margin-top: -50px;
		}
		@include break(nav,mobile){
			//margin-top: 25px;
		}
	}

	&[size="Large"]{
		[card] > figure > img {
			height: 60px;
		}
	}

	&[size=""],&[size="Normal"] {
		[card] > figure > img {
			height: 30px;
		}
	}
	
	[card]{
		width: 100px;
		margin: 0 5px;
		text-align: center;
		padding-bottom: 14px;
		white-space: normal;
		@include break(nav,mobile){
			padding-top: 14px;
		}
		> figure {
			
			> * {
				
			}
			> img {
				margin: 0 auto;
				width: auto;
			}
			> [cta], > figcaption {
				color: #4e4e4e;
				font-weight: $font-weight-light;
				font-size: 12px;
				margin-top: 8px;
				padding-top: 0;
				&::after {
					display: none;
				}
			}
		}
	}
}