[support] {
	padding: 3%;
	inner-content {
		max-width: 250px;
		min-width: 0;
		margin: 0 auto;
	}
	@include break(tab,desktop){
		> comp-container {
			&:last-child:not(:first-child){
				border-left: 1px solid transparentize($color-steel,0.6);
			}
		}
	}
}