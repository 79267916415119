body {
	//padding-top: 80px;
	overflow-x: hidden;
	position: relative;
	left: 0;
	transition: left 0.4s ease;
	&.push-left, &.push-right {
		overflow: hidden;
		-webkit-overflow-scrolling: none;
	}

	&.push-right {
		&.off-canvas-full-screen {
			left: -100%;
			.nav-fixed & {
				> nav, .fixed .page-nav-inner{
					left: -100%;
				}
			}
		}
	}
}
div.off-canvas {
	background: #fff;
	position: fixed;
	height: 100%;
	top: 0;
	z-index: 1000;
	transition: all 0.4s ease;
	overflow: hidden;
	-webkit-overflow-scrolling:touch;
	box-shadow: 0 0 7px rgba(0,0,0,0.18);
	&.right {
		&.full-screen {
			right: -100%;
			width: 100%;
		}
		&.slide-open {
			right: 0;
		}
	}
	&.left {
		&.slide-open {
			left: 0;
		}
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		transition: all 0.4s ease;
		overflow-x: hidden;
		overflow-y: auto;
	}
	
	> .out, &.reverse > .in { // &.left > .in, &.left.reverse > .out, 
		transform: translateX(-100%);
	}

	> .in, &.reverse > .out { // &.left > .out, &.left.reverse > .in, 
		transform: translateX(100%);
	}

	header {
		display: flex;
		justify-content: flex-start;
		> * {
			line-height: 68px;
			height: 68px;
			padding: 0;
			margin: 0;
			border: 0;
			color: $btn-default-color;
			background-color: $btn-default-bg;
			flex: 1;
		}
		> button {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 0 !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			padding: 30px 40px;
			border-right: 1px solid rgba(255,255,255,0.25);
			&::after {
				content: "×";
				font-size: 4rem;
			}
		}
		> h1 {
			display: none;
			padding-left: 25px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		> .back {
			cursor: pointer;
			
			&::before {
				content: "";
				display: inline-block;
				margin-right: 38.4px;
				width: 11px;
				background: url(../images/chevron-thin-left.png) no-repeat center center;
				background-size: contain;
				height: 22px;
			}
		}
		> button, > .back {
			transition: background-color 0.5s;
			html:not(.touch) &:hover {
				background-color: darken($btn-default-bg, 20);
			}
		}
	}
	inner-content {
		width: 90%;
		max-width: 1014px;
		margin: 0 auto;
		padding: 10%;
		.images {
			.image {
				display: inline-block;
				width: 240px;
				margin: 0 20px 20px 0;
				vertical-align: top;
				h5 {
					display: block;
					line-height: 1;
					padding: 5px 0;
					margin: 0;
					text-transform: none;
				}
				a {
					display: block;
				}
			}
		}
	}
}
div.off-canvas-cover {
	transition: all 0.4s ease;
	position: absolute;
	z-index: 13;
	top: 0 !important;
	left: 0 !important;
	bottom: 0;
	right: 0;
	width: 100% !important;
	height: 100% !important;
	background: transparent;
	&.show {
		background: rgba(0,0,0,0.8);
	}
}