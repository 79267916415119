%glue-comp-padding {
	padding: 50px 55px;
	@include break(nav,mobile){
		// padding: 32px 10px;
		padding: 10px;
	}
}

comp-glue {
	@extend %block;
	@extend %top-level-component;
	> comp-callout {
		@extend %glue-comp-padding;
	}
	> comp-feature {
		> comp-container {
			@extend %glue-comp-padding;
			> inner-content {
				padding: {
					top: 0 !important;
					bottom: 0 !important;
				}
			}
		}
		&:not([crop="All"]):not([crop="Side"]){
			@include break(nav,desktop){
				> comp-container comp-background {
					width: auto;
					height: auto;
					top: 50px;
					left: 50px;
					right: 50px;
					bottom: 50px;
				}
			}
		}
		&:not([multi-image]) {
			comp-background {
				&:not(:first-child){
					@include break(nav,mobile){
						position: absolute;
						//top: 32px;
						left: 10px;
						right: 10px;
						width: auto;
					}
				}
			}
		}
	}
}