// Expandable 
@include responsiveExtend(collapsible-collapsed){
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    margin: {
        top: 0;
        bottom: 0;
    }
    padding: {
        top: 0;
        bottom: 0;
    }
    animation: collapse 0.25s linear;
    @include transition(padding-top,padding-bottom,margin-top,margin-bottom);
}
@include responsiveExtend(collapsible-expanded) {
    animation: collapse-expand 0.25s linear;
}

@keyframes collapse-expand {
0% {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}
99% {
    max-height: 100vh;
    opacity: 1;
    overflow: hidden;
    //width: 99.999%; // Safari Force Repaint
}
100% {
    max-height: none;
    opacity: 1;
    overflow: visible;
    //width: 100%; // Safari Force Repaint
}
}
@keyframes collapse {
0% {
    max-height: none;
    opacity: 1;
    overflow: visible;
}
1% {
    max-height: 100vh;
    opacity: 1;
    //width: 99.999%; // Safari Force Repaint
    overflow: hidden;
}
100% {
    max-height: 0;
    opacity: 0;
    //width: 100%; // Safari Force Repaint
    overflow: hidden;
}
}