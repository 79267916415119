nav[pagination] {
	@extend %top-level-component;
	@include comp-padding(vert,0,!important);
	text-align: center;
	a {
		@extend %pagination-button;
		&.active {
			@extend %pagination-button-active;
			cursor: default;
		}
	}
}