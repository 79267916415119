comp-feature {
	@extend %top-level-component;
	@include break(nav,desktop){
		display: flex;
	}
	@include break(nav,mobile){
		display: block;
	}
	&:not([images="0"]), comp-glue[showcase] &{
		&[image-position="Center"] {
			@include break(nav,desktop){
				flex-direction: column;
				max-width: 658px;
				margin: {
					left: auto;
					right: auto;
				}
				text-align: center;
				h1, h2 {
					background-position: top center;
				}
				a {
					display: inline-block;
					margin: {
						left: 25px;
						right: 25px;
					}
					clear: none;
					float: none;
				}
				p {
					max-width: 450px;
					margin: {
						left: auto;
						right: auto;
					}
				}
			}
		}
		> *{
			flex: 1;
			
			&[size="Narrow"] {
				flex: 0.7;
			}
			&[size="Wide"] {
				flex: 1.3;
			}

			&[position="Right"] {
				order: 1;
			}
		}
		&[crop="Push"] > * {
			@include comp-padding(vert,0);
			&[position="Left"] {
				padding-left: 0;
			}
			&[position="Right"] {
				padding-right: 0;
			}
		}
		&[crop="All"] {
			@include comp-padding(vert,0,!important);
			comp-container:not([position]) {
				inner-content {
					padding: {
						top: 5%;
						bottom: 5%;
					}
				}
				
				@include break(nav,desktop){
					&:first-child {
						padding-right: 55px;
					}
					&:last-child {
						padding-left: 55px;
					}
				}
			}
		}
		&[crop="Wide"] {
			position: relative;
			> comp-container {
				position: static;
				comp-background {
					@extend %mobile-swap;
					background: {
						position: left top;
						size: 100% auto;
					}
					@include break(nav,mobile){ 

						background: {
							size: auto 100%;
						}
					}
				}
				inner-content {
					> a[role="button"] {
						@extend %button-block;
						// border: 1px solid $color-blue;
						// padding: 5px 10px;
					}
				}
			}
		}
		&[crop="Extend"] {
			position: relative;
			padding: {
				top: 10%;
				bottom: 10%;
			}
			> comp-container {
				position: static;
				comp-background, > video {
					@extend %mobile-swap;
					
					@include break(nav,desktop){
						left: 50%;
						width: 100vw;
						max-width: 1390px;
						transform: translateX(-50%);
						background: {
							position: center top;
							size: cover;
						}	
					}
					@include break(nav,mobile){ 
						left: 0 !important;
						right: 0 !important;
						bottom: 0 !important;
						padding-bottom: 0 !important;
						max-height: 100%;
						background: {
							size: auto 100%;
						}
					}	
				}
				&[jwpid] + comp-container {
					position: relative;
					z-index: 1;
				}
			}
		}
	}
	body > &[images="0"] {
		comp-container[position] {
			display: none;
		}
	}
	
	inner-content {
		> a[role="button"] {
			clear: left;
			float: left;
			margin-bottom: 10px;
		}
		p > a[role="button"] {
			display: inline-block;
			margin-bottom: 10px;
			margin-right: 30px;
		}
	}
	&[promotion] {
		position: relative;
		margin-top: 34px;
		comp-background {
			background-size: cover;
		}
		comp-container {
			overflow: visible;
			img {
				margin-top: -85px;
			}
		}
	}
	&[multi-image] {
		// @include break(nav,mobile){
		// 	display: none;
		// 	// [crop="None"]{
		// 	// 	padding-bottom: 80%;
		// 	// }
		// }
		comp-background {
			transition: opacity 0.25s;
			&:not(.selected){
				opacity: 0;
			}
		}
	}
	&:not([multi-image]):not([crop="Wide"]):not([crop="Extend"]) {
		comp-background {
			transition: opacity 1s;
			// &:first-child {
			// 	@include break(nav,mobile){
			// 		margin-bottom: 32px;
			// 	}
			// }
			&:not(:first-child){
				opacity: 0;
				@include break(nav,mobile){
					position: absolute;
					top: 0;
					left: 20px;
					right: 20px;
					width: auto;
				}
			}
		}
		&.inview {
			comp-background {
				&:not(:first-child){
					opacity: 1;
				}
			}
		}
	}
	> comp-container > video {
		@extend %abs-cover;
		min-height: 100%;
		min-width: 100%;
		object-fit: cover;
	}
	> comp-container[jwpid] {
		@include iconize(play-circle,after);
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 100px;
			opacity: 0.75;
			transition: opacity 0.2s;
		}
		&[jwpid="+"]::after{
			display: none;
		}
		&:not([jwpid="+"]){
			cursor: pointer;
			html:not(.touch) &:hover {
				&::after {
					opacity: 1;
				}
			}
		}
	}
}