comp-tabs {
	@extend %block;
	@extend %top-level-component;
	> nav {
		@extend %tab-nav;
		border-top: 1px solid $divider-color-gray;
	}
	> inner-content {
		padding-top: 20px;
		> h4 {
			display: none;
		}
		&.expanded {
			@extend %collapsible-expanded;
		}
		&:not(.expanded) {
			@extend %collapsible-collapsed;
		}
		
	}
}