%resource-item-container {

	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	margin: 0 -6px;
	
	> * {
		@include break(nav,desktop){
			flex-basis: 25%;
			max-width: 25%;
		}
		@include break(nav,mobile){
			flex-basis: 50%;
			max-width: 50%;
		}
		@media(max-width: 650px){
			flex-basis: 100%;
			max-width: 100%;
		}
		// display: block !important;
		display: flex;
		box-sizing: border-box;
		// padding: 6px 6px 6px 30px;
		padding: 6px;
		line-height: 1.4;
		position: relative;
		// @include iconize(doc1);
		// &[resource="IMG"] {
		// 	@include iconize(image);
		// }
		// &[resource="PDF"] {
		// 	@include iconize(doc2);
		// }
		// &::before {
		// 	position: absolute;
		// 	top: 11px;
		// 	left: 0;
		// 	font-size: 15px;
		// 	color: #bac2c5;
		// }
		
		.thumb {
			display: inline-block;
			min-width: 60px;
			min-height: 60px;
			background: {
				repeat: no-repeat;
				position: left top;
				size: contain;
			}
		}
		.label {
			margin-left: 15px;
			max-width: 70%;
			display: block;
		}
	}
}

%resource-padding {
	@include comp-padding(vert,6%);
	@include comp-padding(side,10%);
}

comp-resources {
	@extend %block;
	&:not([page]){
		[group] {
			&.open > div {
				@extend %collapsible-expanded;
			}
			&:not(.open) > div {
				@extend %collapsible-collapsed;
			}
			> * {
				border-bottom: 1px solid transparentize($color-steel,0.6);
			}
			> h3 {
				text-align: center;
				padding: 3.4%;
				background: $color-gray;
				margin: 0;
				cursor: pointer;
				transition: background-color 0.25s;
				// @include iconize(doc1);
				// &[resources="IMG"] {
				// 	@include iconize(image);
				// }
				// &[resources="PDF"] {
				// 	@include iconize(doc2);
				// }
				// &::before {
				// 	display: inline-block;
				// 	vertical-align: bottom;
				// 	color: #7d7e80;
				// 	font-size: 29px;
				// 	margin-right: 18px;
				// }
				body:not(.touch) &:hover {
					background-color: darken($color-gray,10);
				}
			}
			> div {
				// padding: 5.9% 4.2%;
				padding: 5.9% 25px;
				@extend %resource-item-container;
			}
			
			&.open {
				h3 {
					background-color: darken($color-gray,10);
				}
			}
		}
	}
	&[page]{
		@extend %top-level-component;
		padding-top: 0 !important;
		font-size: 20px;
		
	
		[group], [assets] {
			
		}
		[group]{
			font-size: 0.5em;
			> h3 {
				margin-top: 0;
				margin-bottom: 18px;
				padding: 0;
				font-size: 6em;
				+ :not(p) {
					margin-top: 37px;
				}
				+ p {
					font-size: 16px;
					+ * {
						margin-top: 37px;
					}
				}
			}
			&:not(:last-child){
				margin-bottom: 37px;
				padding-bottom: 37px;
				border-bottom: 1px solid $divider-color-dark;
			}
		}
		[assets]{
			font-size: 18px;
			@extend %resource-item-container;
			+ [group] {
				margin-top: 37px;
				padding-top: 37px;
				border-top: 1px solid $divider-color-dark;
			}
		}
		> [group] {
			@extend %resource-padding;
			> [group] > [group] {
				h3 {
					font-size: 15px;
					
				}
				> [group] {
					h3 {
						color: $color-blue;
					}
				}
			}
			> h3 {
				margin-bottom: 18px;
				@include break(nav,mobile){
					font-size: 3.5em;
				}
				+ :not(p) {
					margin-top: 55px;
				}
				+ p {
					+ * {
						margin-top: 55px;
					}
				}
			}
			&:nth-child(odd){
				background: $color-gray;
			}

			border-bottom: 0 !important;
			margin-bottom: 0 !important;
			
		}
		
	}
}

comp-container[resources]{
	padding: 0 !important;
	margin-bottom: 0;
	> inner-content {
		@include comp-padding(side,10%);
	}
	
}

form[filters] {
	border-top: 1px solid #d7d7d7;
	margin-top: -40px !important;
	padding-top: 40px !important;
}

div[resources][filters] {
	display: flex;
	justify-content: flex-start;
	padding-top: 40px;
	justify-content: center;
	max-width: 900px;
	margin: 0 auto;
	@include break(tab,mobile){
		flex-direction: column;
	}
	> .label {
		padding: 10px;
		font-size: 14px;
		font-weight: $font-weight-demi;
		font-family: $headings-font-family;
		text-transform: uppercase;
		box-shadow: 0 0 0 1px $divider-color-dark;
		display: flex;
		justify-content: center;
		align-items: center;
		@include break(tab,desktop){
			flex-basis: 174px;
		}
	}
	> div:not(.label) {
		display: flex;
		flex-wrap: wrap; 

		> .filter {
			flex-basis: 25%;
			flex-grow: 1;
			//border: $divider-border-dark;
			box-shadow: 0 0 0 1px $divider-color-dark;
			background: #f8f8f8;
			font-size: 14px;
			font-family: $headings-font-family;
			padding: 12px 12px 12px 58px;
			position: relative;
			text-align: left;
			cursor: pointer;
			transition: background-color 0.25s;
			html:not(.touch) &:hover {
				background: darken(#f8f8f8,10%);
			}
			@include iconize(check);
			&::before {
				position: absolute;
				top: 12px;
				left: 12px;
				width: 20px;
				height: 20px;
				line-height: 18px;
				text-align: center;
				background: $color-white;
				border: $divider-border-dark;
				color: $color-blue;
			}
			&:not(.selected) {
				&::before {
					content: "";
				}
			}
		}	
	}
	
}

#modal-lightbox {
	background: transparent;
	.modal-content {
		padding: 0;
		position: relative;
		.close {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			background: transparent url(../images/close-icon.png) no-repeat center center;
			background-size: 20px;
			padding: 0;
			min-width: 0;
		}
		[actions]{
			position: absolute;
			top: 0;
			left: 0;
			height: 36px;
			background: #777;
			padding-right: 16px;
			z-index: 1;
			> a {
				display: inline-block;
				height: 36px;
				line-height: 36px;
				color: #fff;
				font-size: 9px;
				text-transform: uppercase;
				font-family: $headings-font-family;
			}
		}
		.download {
			background: transparent url(../images/download-icon.png) no-repeat 10px center;
			background-size: 20px;
			padding-left: 46px;
		}
		.email {
			margin-left: 20px;
			@include iconize('envelope');
			&::before {
				margin: 0 10px;
				font-size: 20px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		img {
			width: 100%;
		}
		.vid-holder {
			position: relative;
			padding-bottom: 56.25%;
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				bottom: 0;
			}
			.jwplayer {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}
	iframe[name="mailto-target"] {
		position: absolute;
		opacity: 0;
		width: 1;
		height:1;
		left: -1px;
		top: -1px;
	}
}