%abs-cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none; 
}
%block {
	&:not([inline]) {
		display: block;
	}
}
%contain {
	&::before, &::after {
		content: "";
		display: table;
		clear: both;
	}
}
%sitebgstyle {
	background: {
		attachment: fixed;
		repeat: no-repeat;
		position: center 200px;
	}
	@media (max-width: 1345px){
		background-image: none !important;
	}
}
%button-text {
	font-family: $headings-font-family;
	font-weight: $font-weight-demi;
	text-transform: uppercase;
	font-size: 14px;
}
%heading-divider {
	padding-top: 32px;
	background: linear-gradient($link-color,$link-color) left 0 no-repeat;
	background-size: 34px 2px;
}
%heading-center {
	text-align: center;
	background-position: center 0;
}
%tab-nav {
	border-bottom: 1px solid $divider-color-gray;
	overflow: hidden;
	position: relative;
	text-align: center;
	//height: 59px; // Needs to be set to the height of the buttons.
	>[wrap]{
		display: block;
		
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none;
		-webkit-overflow-scrolling: touch;
	}
	button {
		padding: 18px;
		zoom: 1;
		vertical-align: middle;
	}
	> [control] {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 20px;

		@include break(tab,desktop){
			background: $color-white;
			cursor: pointer;
			&::before {
				position: absolute;
				top: 50%;
				display: block;
				text-align: center;
				width: 100%;
				transform: translateY(-50%);
			}
		}
		&.left {
			left: 0;
			@include break(tab,mobile){
				background: linear-gradient(to left, transparent, $color-gray);
			}
			@include break(tab,desktop){
				border-right: 1px solid $divider-color-gray;
				&::before {
					content: "<";
				}
			}
		}
		&.right {
			right: 0;
			@include break(tab,mobile){
				background: linear-gradient(to right, transparent, $color-gray);
			}
			@include break(tab,desktop){
				border-left: 1px solid $divider-color-gray;
				&::before {
					content: ">";
				}
			}
		}
	}
	&:not(.left) > [control].left, &:not(.right) > [control].right {
		display: none;
	}
	>[wrap], button {
		position: relative;
	}
}
%top-level-component {
	body > & {
		&:not([theme="White"]):not(comp-glue){
			@include comp-padding(vert);
		}
		margin: 0 auto 80px auto;
		max-width: $container-max;
		@media(max-width: $container-max + ($container-side-margin * 4)){
			margin: {
				left: $container-side-margin;
				right: $container-side-margin;
			} 
		}
		@media(max-width: 640px){
			margin-bottom: 12.5vw;
		}
	}
}
%pagination-button {
	display: inline-block;
	font-size: 16px;
	font-family: $headings-font-family;
	letter-spacing: 0.1em;
	font-weight: $font-weight-demi;
	line-height: 40px;
	width: 40px;
	text-align: center;
	height: 40px;
	color: #8b8d8e;
	border-radius: 50%;
	&:hover {
		text-decoration: none;
	}
}

%pagination-button-active {
	background: $color-blue;
	
	color: $color-white;
}

%select-base {
	border-radius: 0;
	appearance: none;
	&::-ms-expand {
		display: none;
	}
}

%select-arrow {
	@extend %select-base;
	color: $text-color;

	background: {
		image: url(imagePath(down-arrow,svg)), solidGradient(#dde0e3);
		repeat: no-repeat;
		position: calc(100% - 14px) center, 100% 0;
		size: 9px 16px, 40px 100%;
		color: $color-gray;
	}
	padding: 4px 50px 4px 26px;
	border: 0;
}

%mobile-swap {
	// First Item of type visible on desktop, second on mobile.
	@include break(nav,mobile){
		&:first-of-type:not(:last-of-type) {
			background-image: none !important;
			display: none;
		}
	}
	@include break(nav,desktop){
		&:last-of-type:not(:first-of-type) {
			background-image: none !important;
			display: none;
		}
	}
}