comp-glue[showcase] {
	@extend %contain;
	position: relative;
	overflow: hidden;
	> .collapsible {
		&.collapsed {
			@extend %collapsible-collapsed;
		}
		&:not(.collapsed) {
			@extend %collapsible-expanded;
		}
	}
	> * {
		clear: none;
		@include break(nav,desktop){
			comp-background {
				display: none;
			}
		}
		&:not(:first-of-type){
			h2 {
				padding-top: 0;
				background: none;
			}
		}
	}
	> comp-background {
		background-size: 100% auto;
	}
	> .spacer {
		float: left;
	}
	@include break(nav,mobile){
		> comp-background, > .spacer {
			display: none;
		}
	}
	button.show{
		color: $color-blue;
		padding-left: 0;
		&::before {
			display: inline-block;
			background: $color-blue;
			color: $color-black;
			width: 26px;
			height: 26px;
			vertical-align: middle;
			text-align: center;
			line-height: 28px;
			font-size: 20px;
			box-sizing: content-box;
			font-family: sans-serif;
			margin-right: 16px;
		}
		&.more {
			&::before {
				content: "\02193";
			}
			&::after {
				content: "Show More";
			}
		}
		&.less {
			&::before {
				content: "\02191";
			}
			&::after {
				content: "Show Less";
			}
		}
	}
}