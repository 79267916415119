a, span {
	&[card]{
		display: inline-block;
		vertical-align: top;

		> figure {
			display: block;
			> * {
				display: block;
			}
			> img {
				width: 100%;
				height: auto;
			}
			> figcaption {
				padding-top: 18px;
				@include break(nav,mobile) {
					font-size: 14px;
				}
			}
		}
	}

	&.active {
		span:last-of-type {
			color: $color-blue !important;
		}
	}
}