comp-post {
	@extend %top-level-component;
	@include comp-padding(vert,0,!important);
	display: block;

	&[press] {
		max-width: 655px;
		comp-cards {
			//@include comp-padding(side,20px,!important);
			> p, [card] {
				@include comp-padding(side,8px);
			}
			img, figcaption, a[role="button"] {
				width: 100% !important;
				float: none;
			}
			figcaption {
				padding: 20px 0 !important;
				font-size: 20px;
			}
			a[role="button"] {
				padding: 2px 0 !important;
			}
		}
	}

	> :first-child {
		h2 {
			margin-top: 0;
			padding-top: 0;
			background: none;
		}
		[byline] {
			font-size: 12px;
			font-weight: $font-weight-light;
			margin-bottom: 15px;
			address {
				margin-bottom: 0;
			}
		}
	}
	
	@include break(nav,desktop){
		&:not([press]){
			display: flex;
			> *{
				flex: 1;
				&:last-child {
					flex-basis: 55%;
					max-width: 55%;
					overflow: visible;
					strong {
						> img {
							max-width: 194.5666%;
							width: 200%;
							float: right;
							margin-bottom: 15px;
							margin-right: -20px;
						}
					}
				}
			}			
		}
	}

	

	
	inner-content {
		
	}
}

nav[post] {
	@extend %top-level-component;
	@extend %tab-nav;
	position: relative;
	padding: 15px 0 !important;
	@include break(tab,desktop){
		text-align: left;
	}
	[image] + & {
		margin-top: -80px;
		margin-bottom: 25px;
		@media(max-width: 640px){
			margin-top: -12.5vw;
		}
	}
	comp-post + & {
		border-top: 3px solid rgba(56,58,61,0.4);
	}
	social-links {
		@include break(tab,desktop){
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		@include break(tab,mobile){
			display: block;
			margin-top: 20px;
		}
		a {
			@extend %pagination-button;
			border: 1px solid #8b8d8e;
			margin: 6px;
			body:not(.touch) &:hover {
				@extend %pagination-button-active;
			}
		}
	}
}

#disqus_thread {
	@extend %top-level-component;
}