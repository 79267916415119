comp-videos {
	@extend %block;
	@extend %top-level-component;
	@include comp-padding(vert,0,!important);
	margin-top: 40px;
	overflow: hidden;
	position: relative;
	> header {
		@extend %contain;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		transition: transform 0.25s;
		z-index: 1;
		text-align: right;
		padding: 40px;
		background: $color-black;
		@include break(nav,mobile){
			display: none;
		}
		&:not(.active){
			transform: translateY(-100%);
		}
		
		h2 {
			float: left;
			text-align: left;
			margin-top: 0;
		}
		a {
			display: inline-block;
			margin-left: 53px;
			padding: 42px 0 0 0;
			color: $color-blue !important;
			
		}
	}
	> div {
		width: 100% !important;
		height: 0 !important;
		padding-bottom: 56.25% !important;
	}
	> nav {
		overflow-x: auto;
		white-space: nowrap;
		background: #191919;
		text-align: center;
		padding: 20px 10px;
		> * {
			white-space: normal;
			width: 137px;
			margin: 0 10px;
			color: $color-blue;
			font-size: 14px;
			img {
				transition: box-shadow 0.25s, opacity 0.25s;
			}
			&.selected {
				img {
					box-shadow: 0 0 0 2px $color-blue;
				}
			}
			&:not(.selected) {
				cursor: pointer;
				html:not(.touch) &:hover {
					img {
						opacity: 0.75;
					}
				}
			}
		}
	}
}

// native video styling override
.jw-state-idle .jw-title {
	.jw-title-primary {
		display: none;
	}
}