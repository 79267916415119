%data-tabs-constrained {
	@include break(nav,desktop){
		width: 75%;
		margin: 0 auto;
	}
	@include break(nav,mobile){
		margin: 0 10px;
	}
}

comp-data-tabs {
	@extend %block;
	@extend %top-level-component;

	> header {
		@extend %data-tabs-constrained;
		text-align: center;
		> nav {
			@extend %tab-nav;
		}
	}

	> inner-content, > footer {
		@extend %data-tabs-constrained;
		background: $color-white;
	}

	> inner-content {
		@extend %block;
		> h4 {
			display: none;
		}
		box-shadow: inset;
		&.expanded {
			@extend %collapsible-expanded;
		}
		&:not(.expanded) {
			@extend %collapsible-collapsed;
		}
	}

	h2 {
		@extend %heading-center;
	}

	> footer {
		padding: 15px;
		color: darken($color-steel,20%);
		font-size: 12px;
	}

	comp-data {
		@include break(nav,desktop){
			display: table;
		}
		margin: 0 auto;
		width: 75%;
	}
	comp-row {
		display: table-row;
		box-shadow: 0 1px 0 $divider-color-gray;
		&:first-child {
			@include break(nav,mobile){
				display: flex;
				flex-direction: column-reverse;
				width: 100%;
				> comp-col {
					flex: 1;
					display: block;
					&.selected {
						padding-bottom: 0;
					}
				}
			}
			comp-col {
				h4 {
					font-size: 14px;
				}
			}

		}
		&:not(:first-child) {
			comp-col {
				h4 {
					font-family: $font-family-sans-serif;
				}
				&:first-child {
					color: $color-steel;
					font-weight: $font-weight-light;
				}
				
			}
		}
		comp-col {
			&:first-child {
				width: 40%;
			}
			&:not(:first-child):not(.selected) {
				display: none;
			}
		}
		h4 {
			margin-bottom: 0;
		}
		.switch, .switch-sub {
			display: flex;
			text-align: center;
			
			> * {
				flex: 1;
				margin: 0;
			}
			h4 {
				cursor: pointer;
				padding: 14px 2px;
				border-radius: 40px;
				background: transparent linear-gradient($color-blue,$color-blue) center center no-repeat;
				background-size: 0 0;
				color: $color-steel;
				transition: background-size 0.15s, color 0.25s;
				&.selected {
					background-size: 100% 100%;
					color: $color-white;
				}
			}
			h6 {
				font-size: 12px;
				color: $color-black;
				font-family: $font-family-sans-serif;
				font-weight: $font-weight-light;
				text-transform: none;
				padding: 8px 20px;
				@include break(nav,mobile){
					padding: {
						left: 2px;
						right: 2px;
					}
				}
			}
		}
		.switch {
			position: relative;
			background: $color-gray;
			border-radius: 40px;
			// > hr {
			// 	position: absolute;
			// 	height: 100%;
			// 	top: 0;
			// 	left: 0;
			// 	width: 50%;
			// 	background: $color-blue;
			// }
		}
	}
	comp-col {
		display: table-cell;
		padding: 20px;
	}
}