html {
	scroll-behavior: smooth;
}

html, body {
	min-height: 100%;
}
body {
	position: relative;
	letter-spacing: 0.02em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include bgimg(Detroit-Logo-Background);
	@extend %sitebgstyle;
	
	@include break(nav,mobile){
		@include bgimg(Dot_Pattern);
		background: {
			repeat: repeat-x;
			position: left top;
			attachment: scroll;
		}
	}
	&.home::after {
		@include bgimg("Detroit-Logo-Background-White-Opaque");
		@extend %sitebgstyle;
		content: "";
		position: absolute;
		top: 50%;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $color-gray;
		z-index: -1;
	}
	&.takeover-active {
		overflow: hidden;
	}
	// &::after {
	// 	content: "REVIEW ONLY - Has not been QA'd";
	// 	position: fixed;
	// 	color: $color-white;
	// 	background: #ba5a5a;
	// 	padding: 10px 60px;
	// 	top: -44px;
	// 	left: -120px;
	// 	font-size: 10px;
	// 	font-family: $headings-font-family;
	// 	font-weight: 700;
	// 	pointer-events: none;
	// 	transform: rotate(-45deg);
	// 	transform-origin: 100% 0;
	// }
	&::after {
		content: "";
		@include bgimg(Detroit-Logo-Background-White-Opaque);
		@extend %sitebgstyle;
		@extend %abs-cover;
		z-index: 1;
		pointer-events: none;
		mix-blend-mode: overlay;
	}
}

iframe {
	left: 0;
}

img, iframe {
	max-width: 100%;
}

img[src=""] {
	display: none !important;
}

@include headings(1,6){
	text-transform: uppercase;
	color: inherit;
	font-weight: $font-weight-demi;
	// &.sub {
	// 	color: $headings-color-light;
	// }
	&:not(:first-child){
		margin-top: 60px;
	}

	h1 + &,
	h2 + &,
	h3 + &,
	h4 + &,
	h5 + & {
		inner-content > &, section > & {
			margin-top: 15px !important;
			color: $headings-color-light;
			line-height: 1.5;
		}
	}
	
	span {
		font-weight: inherit !important;
	}
}
@include headings(1,2){
	@extend %heading-divider;
}

.heading-1 {
	@extend h1;
}
.heading-2 {
	@extend h2;
}

@each $label, $map in $heading-responsive {
	$base: map-get($map, base);
	$break-point: $base / (map-get($map, small) / $smallest-screen);
	@media(max-width: $break-point){
		#{$label} {
			font-size: get-vw($base,$break-point);
		}
	}
}

p.subtext {
	font-size: 0.8em;
}

a[role="button"]:not(.block), [cta]{
	@extend %button-text;
	color: inherit;
	font-size: 16px;

	&::after, &::before {
		//font-family: sans-serif;
		display: inline-block;
		vertical-align: middle;
	}
	&:not([back]){
		@include iconize(link,after);
		&::after{
			//content: "\27F6";
			margin-left: 26px;
		}
	}
	&[back]{
		@include iconize(link);
		&::before {
			//content: "\27F5";
			margin-right: 26px;
			transform: rotate(-180deg);
		}
	}
	html:not(.touch) &:hover {
		text-decoration: underline;
	}
}

button.text{
	@extend %button-text;
	color: inherit;
	border: none;
	background: transparent;
	outline: none;
	&.selected {
		color: $color-blue;
	}
}

%button-block {
	@extend %button-text;
	display: inline-block;
	color: #fff;
	background: $color-blue;
	padding: 10px 20px;
	min-width: 230px;
	text-align: center;
	border-radius: 2px;
	transition: box-shadow 0.25s;
	box-shadow: inset 0 0 0 0 $color-steel;
	text-decoration: none;
	border: 0;
	&:not([disabled]) {
		html:not(.touch) &:hover {
			box-shadow: inset 0 0 0 3px $color-steel;
		}
		&:active, &:focus {
			box-shadow: inset 0 0 0 100px $color-steel;	
		}
	}
	&[disabled] {
		background: transparentize($color-steel,0.7);
	}
}

a[role="button"].block, button:not(.menu-toggle):not(.text):not([type="button"]), input[type="button"], input[type="submit"] {
	@extend %button-block;
}

ul:not(.dealer-services) {

	li {
		position: relative;
		list-style-type: none;
		line-height: 26px;
		&:not(:last-child){
			margin-bottom: 10px;
		}
		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			width: 7px;
			height: 7px;
			background: $color-blue;
			transition: transform 0.25s cubic-bezier(0.390,  0.575, 0.565, 1.000);
			transition-delay: inherit;
			transform: scale(1,1) translateZ(0);
			top: 10px;
			left: -25px;
			border-radius: 50%;
			[theme="Blue"] & {
				background: $color-white;
			}
		}
		&::after {
			content: "";
			position: absolute;
			z-index: 0;
			width: 1px;
			//background: lighten($color-blue,20);
			@include bgborder(lighten($color-blue,20));
			background-position: center top;
			background-size: 100% 100%;
			top: -5px;
			bottom: -23px;
			left: -22px;
			transition: background-size 1s;
			transition-delay: inherit;
			//transform-origin: 50% 0;
			//transform: translateZ(0);

		}
		&:first-child::after {
			top: 15px;
		}
		&:last-child::after {
			display: none;
		}
	}
	&:not(.inview) {
		li::before {
			transform: scale(0,0) translateZ(0);
		}
		li::after {
			
			background-size: 100% 0;
		}
	}

	&[col]:not([col="1"]) {
		li::after {
			display: none;
		}
	}
}

.fade-out {
	transition: opacity 2s;
	opacity: 0 !important;
}

.input-alert {
	&:not(.expanded) {
		@extend %collapsible-collapsed;
	}
	&.expanded {
		@extend %collapsible-expanded;
	}
}